<template>
  <img src="../assets/logo.png" width="50%" height="auto" />
</template>

<script>
export default {
  name: 'LogoView'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
