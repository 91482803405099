import { render, staticRenderFns } from "./ProjectMembersView.vue?vue&type=template&id=b81d8690&scoped=true&"
import script from "./ProjectMembersView.vue?vue&type=script&lang=js&"
export * from "./ProjectMembersView.vue?vue&type=script&lang=js&"
import style0 from "./ProjectMembersView.vue?vue&type=style&index=0&id=b81d8690&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b81d8690",
  null
  
)

export default component.exports