<template>
  <div>
    <!-- <div class="row" v-if="showLogo">
      <div class="col-md"></div>
      <div class="col-md"><LogoView/></div>
      <div class="col-md"></div>
    </div> -->
    <template v-if="!useSplitScreen()">
      <router-view></router-view>
    </template>
    <template v-if="useSplitScreen()">
      <div class="row">
      <div class="col-md-3">
        <ProjectGroupsView></ProjectGroupsView>
      </div>
      <div class="col-md-9">
        <router-view></router-view>
      </div>
    </div>
    </template>
  </div>
</template>

<script>
//import LogoView from '../../Components/LogoView.vue'
import ProjectGroupsView from './Groups/ProjectGroupsView.vue';
export default {
  name: 'ProjectGroupsRouter',
  components : {
  //  LogoView,
  ProjectGroupsView
  },
  watch : {
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    useSplitScreen : function(){
      console.log(window.innerWidth);
      return this.$route.name != "groupsView" && window.innerWidth > 1400;
    }
  },
  data : function(){
    return {
    }
  },
  created : function(){
  }
}
</script>
<style scoped>
.auto-grid {
  --auto-grid-min-size: 12rem;
  list-style:none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 1rem;
  padding-left:0;
}
</style>
