<template>
  <div class="popup">
    <div class="popup-inner">
      <slot />
      <p class="error" v-if="showError==true">An Error Occurred. {{errorMessage}}</p>
      <p>Select a file, once selected press "Upload" to upload - test test test.</p>
      <input id="fileToInput" type="file" @change="onFileChange" />
      <div v-if="selectedFile">
        <button @click="removeFile">Remove File</button>
        <button @click="uploadFile">Upload File</button>
      </div>
      <div style="padding-top:5px">
        <button @click="$emit('dismiss')">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import request from "request";
export default {
  name: "FileUploader",
  props: ["ToggleUpload", "recordId", "uploadType", "projectId"],
  methods: {
    onFileChange(event) {
      this.selectedFile = event.target.files[0];
      // this.fileResult = event.target.result;
      // this.theEvent = event;

      //const input = document.querySelector('input[type="file"]');
      //this.inputFile = input.files[0];
    },
    removeFile: function() {
      //this.inputFile = "";
      this.selectedFile = null;
    },
    uploadFile: function() {
      var self = this;

      function postS3(self, maccResponseBody, file) {
        console.log("postS3");

        const fileReader = new FileReader();
        var fileBody = fileReader.readAsArrayBuffer(file);
        console.log(fileBody);

        var reader = new FileReader();

        reader.onload = function(e) {
          //bfile = e.target.result
          console.log("Array buffer");
          console.log(e.target.result);
          console.log(e.target.result.byteLength); // this shows bfile

          var buffer = Buffer.from(new Uint8Array(e.target.result));
          request(
            {
              url: maccResponseBody["presignedURL"],
              body: buffer,
              json: false,
              headers: {
                "Content-Length": e.target.result.byteLength.toString()
              },
              method: "PUT"
            },
            function(error, response) {
              if (error != null) {
                console.log(error);
              }
              if (response.statusCode != 200) {
                console.log("unsuccessful status code " + response.statusCode);
                self.showError = true;
                self.errorMessage = error;
                return;
              }
              validateFile(self, maccResponseBody["id"]);
            }
          );
        };
        reader.readAsArrayBuffer(file);
      }

      function validateFile(self, maccFileId){
        console.log("validateFile");

        var validateURL = self.requestURL;
        if(self.uploadType == "profile-pic" || self.uploadType == "project-icon"){
          validateURL = validateURL + "/validate";
        } else if (self.uploadType == "task-file"){
          validateURL = validateURL + "/" + maccFileId + "/validate";
        }

        request(
          {
            url: validateURL,
            auth: {
              bearer: window.localStorage.getItem("macchiato-token")
            },
            method: "GET"
          },
          function(error, response) {
            if (response.statusCode != 200) {
              console.log(response.statusCode);
              self.showError = true;
              self.errorMessage = error;
              return;
            }
            window.location.reload();
          }
        );
      }


      function postFile(self, file) {
        console.log("inside postFile");

        request(
          {
            url: self.requestURL,
            json: self.requestBody,
            headers: {
              "x-token-type": "JWT",
              "X-Macchiato-Upload-Method": "web"
            },
            auth: {
              bearer: window.localStorage.getItem("macchiato-token")
            },
            method: "POST"
          },
          function(error, response) {
            if (response.statusCode != 200) {
              self.showError = true;
              self.errorMessage = error;
              return;
            }

            console.log("ready to send to S3");
            postS3(self, response.body, file);
          }
        );
      }

      self.getMaccRequest();
      postFile(self,self.selectedFile);

    },
    getMaccRequest : function(){

      var fileSize = this.selectedFile.size;
      var fName = this.selectedFile.name;
      var extension = fName.substring(fName.lastIndexOf(".") + 1);


      if(this.uploadType == "profile-pic"){

        this.requestBody = {
          size : fileSize,
          fileExtension : extension
        };
        this.requestURL = window.apiUrl + "/me/profile-picture";

      } else if (this.uploadType == "task-file"){
        
        this.requestBody = {
          file : {
            size : fileSize,
            fileName : fName,
            fileExtension : extension,
            displayName : fName
          },
          taskId : this.recordId
        };

        this.requestURL = window.apiUrl + "/projects/" + this.projectId + "/files";

      } else if (this.uploadType == "project-icon"){

        this.requestBody = {
          size : fileSize,
          fileExtension : extension
        };
        
        this.requestURL = window.apiUrl + "/projects/" + this.projectId + "/icon";

      }

      console.log("requestURL is " + this.requestURL);
      console.log(this.requestBody);


    }
  },
  data: function() {
    return {
      showFile: false,
      //inputFile: "", // this could probably go
      selectedFile: null,
      //fileURL: "", // this could probably go
      //fileResult: "", // this could probably go
      //theEvent: "", // this could probably go
      showError: false,
      errorMessage: "",
      requestURL: "",
      requestBody: ""
    };
  },
  created: function() {
    //this.fetchProfilePicture();
    console.log("project id is " + this.projectId);
    console.log("second project id is " + this.$route.params.projectId);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-inner {
  background: #fff;
  padding: 32px;
}

img {
  width: 30%;
  margin: auto;
  display: block;
  margin-bottom: 10px;
}

p.error {
  color: red;
}
</style>