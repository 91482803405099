<template>
    <div :id="modalId" class="modal show" style="display:block;" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Group</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('dismiss')"></button>
        </div>
        <div class="modal-body">
          <p>{{description}}</p>
          <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Search...">
          </div>
          <div v-if="loading"><span class="fa fa-spinner fa-spin"></span></div>
          <ul class="list-group" style="max-height:300px;overflow-y:scroll;">
            <li class="list-group-item list-group-item-action" style="cursor:pointer;" v-for="group in groups" v-bind:key="group.id" @click="selectedGroup = group;">
              {{group.name}}<div v-if="selectedGroup != null && group.id == selectedGroup.id" class="float-end"><i class="fa fa-check"></i></div>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="$emit('dismiss')">{{cancelText}}</button>
          <button type="button" class="btn btn-primary" v-if="confirmText != null" @click="$emit('confirm', selectedGroup);$emit('dismiss');">{{confirmText}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import bootstrap from 'bootstrap';.
import request from 'request';
export default {
  name: 'ProjectGroupPicker',
  props : [
    "description",
    "cancelText",
    "confirmText"
  ],
  mounted : function(){
    this.fetchGroups();
  },
  methods : {
    makeid : function(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    fetchGroups : function(){
      this.loading = true;
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/groups",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.groups = jsonObject.groups;
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      modalId : this.makeid(5),
      loading : false,
      selectedGroup : null,
      groups : []
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
