<template>
      <div class="card defaultMargin">
        <div class="card-header customFont">
          <h5 class="text-left float-start">{{appRoot().currentProject.name}}</h5>
          <button class="btn btn-secondary btn-sm dropdown-toggle float-end" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <span class="fa fa-edit"></span>
          </button>
          <ul class="dropdown-menu" style="width:400px;padding:10px;">
            <li style="font-weight:lighter;">Project Options</li>
            <li><hr class="dropdown-divider"></li>
            <li class="dropdown-item dropdown-item-action text-danger" type="button" @click="showDeleteAlert = true;">Delete Project</li>
            <!-- Change Project Icon -->
            <li class="dropdown-item dropdown-item-action" type="button" @click="showUpload=true;">Change Project Icon</li>
            <!-- Share Project -->
            <li class="dropdown-item dropdown-item-action" type="button" @click="shareProject()">Share Project</li>
          </ul>
        </div>
        <div class="card-body" :style="containerStyle()">
          <ul class="auto-grid">
            <GridTile v-if="hasPermissions('macchiato_project_edit_permissions')" icon="fa-user-check" title="Members" description="Invite project members" :count="statistics.members" tint="rgb(18,153,226)"  @click="navigateTo('members');"></GridTile>
            <GridTile v-if="hasPermissions('macchiato_project_edit_permissions')" icon="fa-user-shield" title="Groups" description="Manage groups and permissions" :count="statistics.groups" tint="rgb(144,50,209)" @click="navigateTo('groups');"></GridTile>
            <GridTile v-if="hasPermissions('macchiato_project_edit')" icon="fa-layer-group" title="Queues" description="View and manage project queues" :count="statistics.queues" tint="rgb(151,209,81)" @click="navigateTo('queues');"></GridTile>
            <GridTile v-if="hasPermissions('macchiato_project_edit')" icon="fa-tags" title="Tags" description="Create tags for requests and tasks" :count="statistics.tags" tint="rgb(249,146,48)" @click="navigateTo('tags');"></GridTile>
            <GridTile icon="fa-list-ul" title="Requests" description="Create and view project requests" :count="statistics.requests" tint="rgb(249,54,68)" @click="navigateTo('requests');"></GridTile>
            <GridTile v-if="hasPermissions('macchiato_project_view_task')" icon="fa-list-ol" title="Tasks" description="Create and view all tasks" :count="statistics.tasks" tint="rgb(18,153,226)" @click="navigateTo('tasks');"></GridTile>
            <GridTile v-if="hasPermissions('macchiato_project_view_task')" icon="fa-columns" title="Kanban" description="View your tasks and queues in a Kanban" tint="rgb(144,50,209)" @click="navigateTo('kanban');"></GridTile>
            <GridTile icon="fa-sliders-h" title="Preferences" description="Notifications, usage and limits." tint="rgb(151,209,81)" @click="navigateTo('preferences');"></GridTile>
          </ul>
        </div>

        <FileUploader v-if="showUpload" uploadType="project-icon" :projectId="this.$route.params.projectId" @dismiss="toggleUpload()">
          <h2>Change Project Icon</h2>
        </FileUploader>

        <AlertModal
           v-if="showDeleteAlert"
           title="Are you sure?"
           description="Are you sure you want to delete this project? This cannot be undone!"
           cancelText="Cancel"
           confirmText="Yes, Delete"
           confirmButtonClass="btn-danger"
           @dismiss="showDeleteAlert = false"
           @confirm="deleteProject()">
         </AlertModal>
      </div>
</template>

<script>
import AlertModal from '../../../Components/AlertModal.vue'
import FileUploader from '../../../Components/FileUploader.vue'
import GridTile from '../../../Components/GridTile.vue'
import request from 'request';
export default {
  name: 'ProjectMenu',
  components : {
    GridTile,
    AlertModal,
    FileUploader
  },
  watch : {
  },
  methods : {
    hasPermissions : function(prefix){
      let permissionIdentifier = prefix + "_" + this.$route.params.projectId;
      let permissionRecordIndex = window.appRoot.currentPermissions.findIndex(function(record) {
        return record.permissionId == permissionIdentifier;
      });
      if (permissionRecordIndex > -1) {
        return window.appRoot.currentPermissions[permissionRecordIndex].access;
      }
      return false;
    },
    containerStyle : function(){
      return {
        "max-height" : (window.innerHeight - 140) + "px !important",
        "height" : (window.innerHeight - 140) + "px !important",
        "overflow-y" : "auto"
      }
    },
    appRoot : function(){
      return window.appRoot;
    },
    navigateTo : function(path){
      window.location.href = "/projects/" + this.$route.params.projectId + "/" + path;
    },
    deleteProject : function(){
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "DELETE"
        }, function(){
          window.location.href = "/";
        });
      }
      fetchWith(this);
    },
    fetchProjectStatistics : function(){
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/statistics",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
              scope.statistics = jsonObject;
              return
          }
        });
      }
      fetchWith(this);
    },
    handleResize : function(){
      this.$forceUpdate();
    },
    shareProject : function(){
      navigator.share({
        title : 'Check out this project on Macchiato',
        text : 'Have a look at this project I am working on using the Macchiato app.',
        url : this.$route.params.projectId
      })
    }
  },
  data : function(){
    return {
      showDeleteAlert : false,
      showUpload : false,
      statistics : {
        targets : null,
        tags : null,
        groups : null,
        tasks : null,
        queues : null,
        members : null,
        requests : null
      }
    }
  },
  created : function(){
    this.fetchProjectStatistics();
    window.addEventListener('resize', this.handleResize);
  }
}
</script>
<style scoped>
.auto-grid {
  --auto-grid-min-size: 12rem;
  list-style:none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 1rem;
  padding-left:0;
}
</style>
