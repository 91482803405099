<template>
  <div class="card defaultMargin">
    <div class="card-header customFont d-flex justify-content-between align-items-center">
      <h5>{{appRoot().currentProject.name}} - New Queue</h5>
    </div>
    <div class="card-body text-start">
      <div class="mb-3">
        <label class="form-label">Queue Label</label>
        <input type="text" class="form-control" v-model="name">
      </div>
      <div class="mb-3">
        <label class="form-label">Select Queue Colour</label>
        <input type="color" class="form-control form-control-color" v-model="hexColor">
      </div>
      <div class="mb-3">
        <div class="btn btn-warning float-start" @click="cancel()">Cancel</div>
        <div class="btn btn-primary float-end" @click="createProjectQueue()">Save</div>
      </div>
    </div>
  </div>
</template>

<script>
import request from 'request';
export default {
  name: 'NewQueue',
  components : {
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    cancel : function(){
        window.location.href = "/projects/" + this.$route.params.projectId + "/queues";
    },
    createProjectQueue : function(){
      if (this.name == null) {
        return;
      }
      var fetchWith = function(scope) {

        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/queues",
          json : {
            name : scope.name,
            hexColor: scope.hexColor,
            sortIndex: scope.queues.length + 1
          },
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "POST"
        }, function(error, response, json){
          if (response.statusCode != 201){
            return
          }
          if (error != null){
            console.log("whatttt");
            return
          }
          if (json != null){
            //Navigate to that task using the new identifier created...
            window.location.href = "queues";
            return
          }
        });
      }
      fetchWith(this);
    },
    fetchProjectQueues : function(){
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/queues",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.queues = jsonObject.queues;
            return
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      queues : [],
      name : "",
      hexColor: "#000000",
      sortIndex: 0
    }
  },
  created : function(){
    this.fetchProjectQueues();
  }
}
</script>
