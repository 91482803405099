<template>
  <div>
    <EditTag :tagToEdit="tag" v-if="tagToEdit != null" @dismiss="tagToEdit = null"></EditTag>
    <template v-if="tagToEdit == null">
      <div class="card defaultMargin">
        <div class="card-header customFont d-flex justify-content-between align-items-center">
          <h5>{{appRoot().currentProject.name}} - Tag Details - {{tag.title}}</h5>
          <div class="dropdown" v-if="!loading">
            <button class="btn btn-sm btn-dark dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              Options
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li class="dropdown-item" style="cursor:pointer" @click="tagToEdit = tag">Edit Tag</li>
              <li class="dropdown-item text-danger" style="cursor:pointer" @click="showDeleteAlert = true">Delete Tag</li>
            </ul>
          </div>
        </div>
        <div class="card-body text-start">
          <div class="mb-3">
            <h5><span class="badge" :style="tagStyle(tag)">{{tag.title}}</span></h5>
            <!--<label class="form-label">Tag Label</label>
            <h4>{{tag.title}}</h4>-->
          </div>
          <div class="mb-3">
            <label class="form-label">Description</label>
            <p>{{tag.description}}</p>
          </div>
          <div class="mb-3">
            <div class="btn btn-warning float-start" @click="$emit('dismiss')">Back</div>
            <!--<div class="btn btn-primary float-end" @click="updateProjectTag(tagToEdit)">Save</div>-->
          </div>
        </div> 
        <AlertModal
          v-if="showDeleteAlert"
          title="Are you sure?"
          description="Are you sure you want to delete this tag?"
          cancelText="Cancel"
          confirmText="Yes, Delete"
          @dismiss="showDeleteAlert = false"
          @confirm="deleteTag(tag)">
        </AlertModal>
      </div>
    </template>
  </div>
</template>

<script>
import request from 'request';
import EditTag from './EditTag.vue'
import AlertModal from '../../../../Components/AlertModal.vue'
export default {
  name: 'TagDetailView',
  components: {
    EditTag,
    AlertModal
  },
  props : ["tag"],
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    tagStyle : function(tag){
      var styleDict = {
        "background-color" : tag.hexColor,
        "display" : "inline-block"
      }
      return styleDict;
    },
    deleteTag : function(tag) {
      var deleteWith = function(scope) {
        scope.loading = true;
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tags/" + tag.id,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "DELETE"
        }, function(error, response){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          window.location.href = "/projects/" + scope.$route.params.projectId + "/tags";
        });
      }
      deleteWith(this);
      this.$emit('dismiss');
    }
  },
  data : function(){
    return {
      tagToEdit: null,
      showDeleteAlert: false,
      loading: false
    }
  },
  created : function(){
    console.log('Tag detail view presented.')
  
  }
}
</script>
<style>
.tagClass {
  height:28px;
  border-radius: 8px;
  line-height: 8px;
  padding: 10px;
  color: #fff;
}
.ontop-left {
  position:absolute;
  left:10;
}
</style>
