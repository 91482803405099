<template>
  <div>
    <!-- <div class="row" v-if="showLogo">
    <div class="col-md"></div>
    <div class="col-md"><LogoView/></div>
    <div class="col-md"></div>
  </div> -->
  <template v-if="!loading">
    <br/>
    <div class="container text-start">
      <div class="row">
        <h3>Notifications</h3>
        <h4>Last 30 Days</h4>
        <div class="text-end">
          <h5 class="text-danger" style="cursor:pointer;" @click="clearNotifications()">Clear All</h5>
        </div>
      </div>
      <hr/>
      <a class="nostyle" :href="linkFor(notification)"  v-for="notification in notifications" v-bind:key="notification.id">
        <div class="row clickable">
          <h5><span class="fa fa-bell"></span>  {{notification.title}}</h5>
          <p>{{notification.description}}</p>
          <p class="text-muted">{{dateStringForEpoch(notification.createdAt)}}</p>
        </div>
      </a>
    </div>
  </template>
  <template v-if="loading == true">
    <div class="row">
      <div class="col-md"></div>
      <div class="col-md">
        <div class="card defaultMargin">
          <div class="card-header">
            Please Wait
          </div>
          <div class="card-body">
            <h5 class="card-title">Fetching Notifications</h5>
            <p class="card-text">Please wait, we're just fetching your notifications...</p>
          </div>
        </div>
      </div>
      <div class="col-md"></div>
    </div>
  </template>
</div>
</template>

<script>
//import LogoView from '../../Components/LogoView.vue'
export default {
  name: 'NotificationsHomeView',
  components : {
    //  LogoView,
  },
  watch : {
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    linkFor : function(notification) {

      switch (notification.notificationIdentifier) {

        case "new_task":
        return "/projects/" + notification.projectId + "/tasks/" + notification.target;
        case "new_task_comment":
        return "/projects/" + notification.projectId + "/tasks/" + notification.owner;
        case "new_request":
        return "/projects/" + notification.projectId + "/requests/" + notification.target;
        case "new_request_comment":
        return "/projects/" + notification.projectId + "/requests/" + notification.owner;
        default:
        return "";
      }
    },
    clearNotifications : function(){
      var self = this;
      this.$root.$emit("clearNotifications", function() {
        self.fetchNotifications();
      }, function(error){
        console.log(error);
      });
    },
    dateStringForEpoch : function(epoch) {
      let date = new Date(epoch * 1000);
      let dateString = date.toLocaleDateString();
      let timeString = date.toLocaleTimeString();
      return dateString + " at " + timeString;
    },
    fetchNotifications : function(){
      this.loading = true;
      var self = this;
      this.$root.$emit('fetchNotifications',function(notifications) {
        self.loading = false;
        self.notifications = notifications;
        self.notifications.sort(function(a,b){
          if ( a.createdAt > b.createdAt ){
            return -1;
          }
          if ( a.createdAt < b.createdAt ){
            return 1;
          }
          return 0;
        });
        document.title = "Macchiato Notifications";
      }, function(error){
        console.log(error);
      });
    }
  },
  data : function(){
    return {
      loading : false,
      notifications : []
    }
  },
  created : function(){
    this.fetchNotifications();
  }
}
</script>
<style scoped>
.clickable {
  cursor: pointer;
  padding-top:10px;
  border-radius:5px;
}
.clickable:HOVER {
  background:#F1F1F1;
}
a.nostyle:link {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}

a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}
</style>
