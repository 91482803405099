<template>
        <!-- <ul class="auto-grid">
  <li class="gridTile" :style="tagStyle(tag)" @click="$emit('selected', tag)" v-for="tag in tags" v-bind:key="tag.id + parentId">
    <div class="full-width text-center" style="margin-top:4px;">
      <p style="vertical-align:middle;line-height:20px;">{{tag.title}}</p>
    </div>
  </li>
</ul> -->
<div class="row">
  <div>
  <h5 class="float-start margin-right" @click="$emit('selected', tag)" v-for="tag in tags" v-bind:key="tag.id + parentId">
<span class="badge" :style="tagStyle(tag)" >
{{tag.title}}
</span>
</h5>
</div>
</div>
</template>

<script>
export default {
  name: 'TagsView',
  props : ['tags','parentId'],
  methods : {
    iconClass : function(){
      var classDict = {
        "fas" : true,
        "fa-2x" : true
      }
      classDict[this.icon] = true;
      return classDict;
    },
    tagStyle : function(tag){
      var styleDict = {
        "cursor" : "pointer",
        "background-color" : tag.hexColor
      }
      return styleDict;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.gridTile {
  height:28px;
  border-radius: 8px;
  line-height: 8px;
  color: #fff;
}
.gridTile p {
font-size:13px;
}
.margin-right {
  margin-right: 5px;
}
.auto-grid {
  --auto-grid-min-size: 8rem;
  list-style:none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 1rem;
  padding-left:0;
}
</style>
