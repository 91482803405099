<template>
  <div class="card defaultMargin">
    <div class="card-header customFont d-flex justify-content-between align-items-center">
      <h5>{{appRoot().currentProject.name}} - New Group</h5>
    </div>
    <div class="card-body text-start">
      <div class="mb-3">
        <label class="form-label">Group Name</label>
        <input type="text" class="form-control" v-model="groupName">
      </div>
      <div class="mb-3">
        <div class="btn btn-primary float-end" @click="createProjectGroup()">Save</div>
      </div>
    </div>
  </div>
</template>

<script>
import request from 'request';
export default {
  name: 'NewProjectGroup',
  components : {
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    createProjectGroup : function(){
      var fetchWith = function(scope) {

        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/groups",
          json : {
            name : scope.groupName
          },
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "POST"
        }, function(error, response, json){
          if (error != null){
            return
          }
          if (json != null){
            //Navigate to that task using the new identifier created...
            window.location.href = "groups/" + json.id;
            return
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      groupName : ""
    }
  },
  created : function(){
  }
}
</script>
