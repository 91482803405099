<template>
  <div class="card defaultMargin">
    <div class="card-header customFont d-flex justify-content-between align-items-center">
      <h5>{{appRoot().currentProject.name}} - Edit Task</h5>
    </div>
    <div class="card-body" v-if="loading">
      <h5>Please Wait</h5>
      <p>Fetching task details...</p>
      <div>
        <span class="fa fa-spinner fa-spin"></span>
      </div>
    </div>
    <div class="card-body text-start" v-if="!loading">
      <div class="mb-3">
        <label class="form-label">Task Title</label>
        <input type="text" class="form-control" v-model="title">
      </div>
      <ProjectQueuePicker label="Queue" chooseLabel="Choose Queue" changeLabel="Change" :selectedQueue="queue" @didSelect="changeQueue" />
      <ProjectMemberPicker label="Assigned To" chooseLabel="Assign this task" changeLabel="Reassign" :selectedMember="assignedTo" @didSelect="assignMember" />
      <div class="mb-3">
        <label class="form-label">Task Priority</label>
        <select class="form-select form-control" v-model="priority">
          <option value="1">Lowest</option>
          <option value="2">Low</option>
          <option value="3" selected>Medium</option>
          <option value="4">High</option>
          <option value="5">Highest</option>
        </select>
      </div>
      <div class="mb-3">
        <label class="form-label">Task Description</label>
        <textarea type="text" class="form-control" v-model="description"></textarea>
      </div>
      <div class="row">
        <label class="form-label">Tags</label>
        <div class="form-text text-muted">Click / Tap to add or remove tags.</div>
        <TagBuilder :selectedTags="selectedTags"></TagBuilder>
      </div>
      <br/>
      <div class="row">
        <label class="form-label float-start">Time Estimate</label><br/>
      <div class="mb-3" v-if="!useTimeEstimate">
          <label class="form-label">Would you like to apply a time estimate?</label>
          <button class="btn btn-outline-secondary float-end" @click="useTimeEstimate = true;">Estimate Time</button>
      </div>
      <div class="mb-3" v-if="useTimeEstimate">
        <div class="input-group">
          <input type="number" class="form-control text-end" v-model="estimatedDays">
          <span class="input-group-text">days</span>
          <input type="number" class="form-control text-end" v-model="estimatedHours">
          <span class="input-group-text">hours</span>
          <input type="number" class="form-control text-end" v-model="estimatedMinutes">
          <span class="input-group-text">minutes</span>
          <button class="btn btn-outline-danger float-end" @click="useTimeEstimate = false;">Remove Estimate</button>
        </div>
      </div>
      </div>
      <br/>
      <div class="row">
        <label class="form-label float-start">Deadline</label><br/>
      <div class="mb-3" v-if="!useDeadline">
          <label class="form-label">Would you like to apply a deadline?</label>
          <button class="btn btn-outline-secondary float-end" @click="useDeadline = true;">Apply a deadline</button>
      </div>
      <div class="mb-3" v-if="useDeadline">
        <div class="input-group">
          <input type="datetime-local" class="form-control" v-model="deadline">
          <button class="btn btn-outline-danger float-end" @click="useDeadline = false;">Remove Deadline</button>
        </div>
      </div>
      </div>
      <br/><br/>
      <div class="mb-3">
          <div class="btn btn-warning float-start" @click="cancel()">Cancel</div>
        <div class="btn btn-primary float-end" @click="updateProjectTask">Save</div>
      </div>
    </div>
  </div>
</template>

<script>
import TagBuilder from '../../../../Components/TagBuilder.vue'
import ProjectMemberPicker from '../../../../Components/ProjectMemberPicker.vue'
import ProjectQueuePicker from '../../../../Components/ProjectQueuePicker.vue'
import request from 'request';
export default {
  name: 'EditTask',
  components : {
    TagBuilder,
    ProjectMemberPicker,
    ProjectQueuePicker
  },
  watch : {
    estimatedMinutes : function(newValue) {
      if (newValue >= 60) {
        this.estimatedHours += 1;
        this.estimatedMinutes = newValue - 60;
      } else if (newValue < 0) {
        this.estimatedHours -= 1;
        this.estimatedMinutes = eval(newValue) + 60;
      }

      this.timeEstimateInSeconds = 0;
      this.timeEstimateInSeconds += this.estimatedMinutes * 60;
      this.timeEstimateInSeconds += this.estimatedHours * 60 * 60;
      this.timeEstimateInSeconds += this.estimatedDays * 24 * 60 * 60;
    },
    estimatedHours : function(newValue) {
      if (newValue >= 24) {
        this.estimatedDays += 1;
        this.estimatedHours = newValue - 24;
      } else if (newValue < 0) {
        this.estimatedDays -= 1;
        this.estimatedHours = eval(newValue) + 24;
      }

      this.timeEstimateInSeconds = 0;
      this.timeEstimateInSeconds += this.estimatedMinutes * 60;
      this.timeEstimateInSeconds += this.estimatedHours * 60 * 60;
      this.timeEstimateInSeconds += this.estimatedDays * 24 * 60 * 60;
    },
    estimatedDays : function() {
      if (this.estimatedDays < 0) {
        this.estimatedDays = 0
      }

      this.timeEstimateInSeconds = 0;
      this.timeEstimateInSeconds += this.estimatedMinutes * 60;
      this.timeEstimateInSeconds += this.estimatedHours * 60 * 60;
      this.timeEstimateInSeconds += this.estimatedDays * 24 * 60 * 60;
    }
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    assignMember : function(member){
      this.assignedTo = member
    },
    changeQueue : function(queue){
      this.queue = queue
    },
    iconFor : function(task){
      var classDict = {
        "fas" : true,
        "fa-2x" : true,
        "float-end" : true
      }
      switch (task.priority) {
        case 5:
        classDict["fa-angle-double-up"] = true;
        classDict["text-danger"] = true;
        break;
        case 4:
        classDict["fa-angle-up"] = true;
        classDict["text-warning"] = true;
        break;
        case 3:
        classDict["fa-angle-right"] = true;
        classDict["text-default"] = true;
        break;
        case 2:
        classDict["fa-angle-down"] = true;
        classDict["text-primary"] = true;
        break;
        case 1:
        classDict["fa-angle-double-down"] = true;
        classDict["text-success"] = true;
        break;
        default:
        break;
      }
      return classDict;
    },
    updateProjectTask : function(){
      var fetchWith = function(scope) {

        let assignedToId = ""
        if (scope.assignedTo != null) {
          assignedToId = scope.assignedTo.id ?? "";
        }
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tasks/" + scope.$route.params.taskId,
          json : {
            title : scope.title,
            detailedDescription: scope.description,
            assignedTo : assignedToId,
            queueId : (scope.queue == null ? "" : scope.queue.id),
            priority : parseInt(scope.priority),
            closed : scope.closed,
            deadline : (scope.useDeadline ? (new Date(scope.deadline).getTime()) / 1000 : null),
            timeEstimateInSeconds : (scope.useTimeEstimate ? scope.timeEstimateInSeconds : 0),
            tags : scope.selectedTags.map(function(tag){
              return tag.id
            })
          },
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "PUT"
        }, function(error, response){
          if (response.statusCode != 200){
            return
          }
            window.location.href = "/projects/" + scope.$route.params.projectId + "/tasks/" + scope.$route.params.taskId ;
        });
      }
      fetchWith(this);
    },
    cancel : function(){
        window.location.href = "/projects/" + this.$route.params.projectId + "/tasks/" + this.$route.params.taskId ;
    },
    pad : function(val) {
      if (val < 10) {
        return "0" + val;
      }
      return val;
    },
    fetchTaskDetails : function(){
      var fetchWith = function(scope) {
        scope.loading = true;
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tasks/" + scope.$route.params.taskId,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.title = jsonObject.title;
            scope.description = jsonObject.detailedDescription;
            scope.selectedTags = jsonObject.tags;
            scope.priority = jsonObject.priority;
            scope.closed = jsonObject.closed;
            scope.assignedTo = jsonObject.assignedTo;
            scope.queue = jsonObject.queue;
            if (jsonObject.deadline != null){
              //scope.deadline = "2021-04-23T17:52"

              var d = new Date(jsonObject.deadline * 1000);
              var year = d.getFullYear();
              var month = scope.pad(d.getMonth() + 1);
              var date = scope.pad(d.getDate());
              var hours = scope.pad(d.getHours());
              var minutes = scope.pad(d.getMinutes());
             scope.deadline = year + "-" + month + "-" + date + "T" + hours + ":" + minutes;
              scope.useDeadline = scope.deadline == null ? false : true;
            }
            if (jsonObject.timeEstimateInSeconds != null && jsonObject.timeEstimateInSeconds > 0) {
              scope.timeEstimateInSeconds = jsonObject.timeEstimateInSeconds;
              scope.estimatedMinutes = jsonObject.timeEstimateInSeconds / 60;
              scope.useTimeEstimate = true;
            }
            return
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      title : "",
      priority : 3,
      description : "",
      selectedTags : [],
      closed : false,
      loading : false,
      assignedTo : null,
      queue : null,
      useDeadline : false,
      deadline : null,
      useTimeEstimate : null,
      timeEstimateInSeconds : 0,
      estimatedDays : 0,
      estimatedHours : 0,
      estimatedMinutes : 0
    }
  },
  created : function(){
    this.fetchTaskDetails();
  }
}
</script>
