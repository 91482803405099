<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import request from 'request';
export default {
  name: 'App',
  methods : {
    fetchNotifications : function(onSuccess, onFail){
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/me/notifications",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          if (response.statusCode != 200){
            onFail("Invalid Status Code - " + response.statusCode);
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            onFail("Error - " + error);
            return
          }
          if (jsonObject != null){
            if (jsonObject.notifications != null){
              scope.notifications = jsonObject.notifications;
              scope.$root.$emit("notificationCount", jsonObject.notifications.length);
            }
          }
          onSuccess(scope.notifications);
        });
      }
      fetchWith(this);
    },
    clearNotifications : function(onSuccess, onFail){
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/me/notifications",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "DELETE"
        }, function(error, response){
          if (response.statusCode != 200){
            onFail("Invalid Status Code - " + response.statusCode);
            return
          }

          if (error != null){
            onFail("Error - " + error);
            return
          }

          scope.notifications = [];
          scope.$root.$emit("notificationCount", 0);
          onSuccess();
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      projects : [],
      notifications : ["test"],
      currentProject : null,
      currentPermissions : null,
      currentUser : null
    }
  },
    metaInfo () {
      return {
        title: this.title,
        meta: [
          { vmid: 'apple-itunes-app', name: 'apple-itunes-app', content:  "app-id=1529092126" }
        ]
      }
    },
  created : function() {
    window.maacchiatoLog("created.");
    if (window.location.protocol !== 'https:' && window.location.hostname != "localhost") {
    window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
    }
    if (this.$route.query.token != null) {
      window.localStorage.setItem('macchiato-token', this.$route.query.token);
      console.log("Saved Token.");
    }
    var self = this;
    this.$root.$on('fetchNotifications', (onSuccess, onFail) => {
      console.log("Fetching Notifications via Emit");
      self.fetchNotifications(onSuccess, onFail);
    });
    this.$root.$on('clearNotifications', (onSuccess, onFail) => {
      console.log("Clearing Notifications via Emit");
      self.clearNotifications(onSuccess, onFail);
    });

    setTimeout(function(){
      self.fetchNotifications(function() {
        console.log("Fetched Notifications.");
      }, function(error){
        console.log(error);
      });
    }, 1000);
  }
}
</script>

<style>
.primaryText {
  color: #000;
}
.secondaryText {
  font-size: 16px;
  color: #777;
}
.defaultPadding {
  padding:15px;
}
.defaultMargin {
  margin:15px;
}
.activeLink {
  color: blue !important;
  font-weight:500 !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

@font-face {
  font-family: 'AllRoundGothic';
  src: url('./Fonts/AllRoundGothic.ttf');
  font-style: normal;
  font-weight: 400;
}

.customFont {
  font-family: 'AllRoundGothic' !important;
}

</style>
