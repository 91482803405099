<template>
  <div>
    <TagsView :tags="selectedTags" parentId="builder" @selected="deselectTag"></TagsView>
    <br/><br/>
    <h5 class="float-start margin-right" v-for="tag in allTags" v-bind:key="tag.id">
      <span class="badge" :style="tagStyle(tag)" @click="selectTag(tag)" >
        {{tag.title}}
      </span>
    </h5>
  </div>
</template>

<script>
import TagsView from './TagsView.vue';
import request from 'request';
export default {
  name: 'TagBuilder',
  props : ['selectedTags'],
  components : {
    TagsView
  },
  methods : {
    selectTag : function(tag){
      this.selectedTags.push(tag);
      let index = this.allTags.indexOf(tag);
      if (index > -1){
        this.allTags.splice(index, 1);
      }
    },
    deselectTag : function(tag){
      console.log("Deselected ");
      console.log(tag);
      this.allTags.push(tag);
      let index = this.selectedTags.indexOf(tag);
      if (index > -1){
        this.selectedTags.splice(index, 1);
      }
    },
    iconClass : function(){
      var classDict = {
        "fas" : true,
        "fa-2x" : true
      }
      classDict[this.icon] = true;
      return classDict;
    },
    tagStyle : function(tag){
      var styleDict = {
        "cursor" : "pointer",
        "background-color" : "clear",
        "border-color" : tag.hexColor,
        "border-style" : "solid",
        "color" : "black"
      }
      return styleDict;
    },
    fetchProjectTags : function(){
      var fetchWith = function(scope) {
        //inject non-filter params
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tags",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.allTags = jsonObject.tags;
            scope.selectedTags.forEach(function(tag){
              let index = scope.allTags.findIndex(function(existingTag){
                return existingTag.id == tag.id;
              });
              if (index > -1){
                scope.allTags.splice(index, 1);
              }
            })
          }
        });
      }
      fetchWith(this);
    }
  },
  created : function(){
    this.fetchProjectTags();
  },
  data : function(){
    return {
      allTags : []
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.gridTile {
  height:28px;
  border-radius: 8px;
  line-height: 8px;
  color: #fff;
}
.gridTile p {
  font-size:13px;
}
.margin-right {
  margin-right: 5px;
}
.auto-grid {
  --auto-grid-min-size: 8rem;
  list-style:none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 1rem;
  padding-left:0;
}
</style>
