<template>
  <div class="container">
    <div class="card defaultMargin">
      <div class="card-header customFont d-flex justify-content-between align-items-center">
        <h5>{{appRoot().currentProject.name}} - Usage</h5>
      </div>
      <div class="card-body text-start" v-if="stats != null && limits != null">
          <h5 class="customFont">{{stats.requests}} of {{limits.requests}} Requests</h5>
          <div class="progress">
            <div class="progress-bar bg-info"   :style="styleFor('requests')" role="progressbar" :aria-valuenow="stats.requests" aria-valuemin="0" :aria-valuemax="limits.requests"></div>
          </div>
          <hr/>
          <h5 class="customFont">{{stats.tasks}} of {{limits.tasks}} Tasks</h5>
          <div class="progress">
            <div class="progress-bar bg-info"  :style="styleFor('tasks')" role="progressbar" :aria-valuenow="stats.tasks" aria-valuemin="0" :aria-valuemax="limits.tasks"></div>
          </div>
          <hr/>
          <h5 class="customFont">{{stats.members}} of {{limits.members}} Members</h5>
          <div class="progress">
            <div class="progress-bar bg-info"  :style="styleFor('members')" role="progressbar" :aria-valuenow="stats.members" aria-valuemin="0" :aria-valuemax="limits.members"></div>
          </div>
          <hr/>
          <h5 class="customFont">{{stats.groups}} of {{limits.groups}} Groups</h5>
          <div class="progress">
            <div class="progress-bar bg-info"  :style="styleFor('groups')" role="progressbar" :aria-valuenow="stats.groups" aria-valuemin="0" :aria-valuemax="limits.groups"></div>
          </div>
          <hr/>
          <h5 class="customFont">{{stats.queues}} of {{limits.queues}} Queues</h5>
          <div class="progress">
            <div class="progress-bar bg-info"  :style="styleFor('queues')" role="progressbar" :aria-valuenow="stats.queues" aria-valuemin="0" :aria-valuemax="limits.queues"></div>
          </div>
          <hr/>
            <h5 class="customFont">Need more?</h5>
            <p>Upgrade your subscription using our iOS App. Web subscriptions are coming soon!</p>
      </div>
    </div>
  </div>
</template>

<script>
import request from 'request';
export default {
  name: 'ProjectUsageView',
  components : {
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    goBack : function(){
      this.$router.back();
    },
    styleFor : function(key) {
      let current = this.stats[key];
      let max = this.limits[key];

      let percentage = parseFloat((current / max)) * parseFloat(100);
      return {
        "width" : percentage + "%"
      }
    },
    fetchStats : function(){
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/statistics",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.stats = jsonObject;
            scope.fetchLimits();
            return
          }
        });
      }
      fetchWith(this);
    },
    fetchLimits : function(){
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/limits",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.limits = jsonObject;
            return
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      stats : null,
      limits : null
    }
  },
  created : function(){
    this.fetchStats();
  }
}
</script>
