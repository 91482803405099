<template>
  <div>
      <div class="row">
        <div class="col-md">
          <div class="card defaultMargin">
            <div class="card-header customFont">
              <h5>Project Preferences</h5>
              </div>
            <div class="card-body">
              <div class="list-group">
                <button class="list-group-item list-group-item-action" style="cursor:pointer;" v-on:click="navigateTo('notifications')">Notification Preferences</button>
                <button class="list-group-item list-group-item-action" style="cursor:pointer;" v-on:click="navigateTo('usage')">Usage and Limits</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectPreferencesMenu',
  components : {
  },
  methods : {
    navigateTo : function(path){
      window.location.href = "/projects/" + this.$route.params.projectId + "/preferences/" + path;
    }
  }
}
</script>
