<template>
  <div>
    <div class="row">
      <div class="col-md"></div>
      <div class="col-md"><LogoView/></div>
      <div class="col-md"></div>
    </div>
    <template v-if="loading == false">
      <div class="row">
        <div class="col-md"></div>
        <div class="col-md">
          <div class="card defaultMargin">
            <div class="card-header customFont d-flex justify-content-between align-items-center">
              <h5>Your Projects</h5>
              <a href="newproject"><div class="btn btn-sm btn-primary">New Project</div></a>
            </div>
            <div class="card-body text-start">
              <p>You have created <strong>{{appRoot().currentUser.projects}}</strong> projects using your <strong>{{appRoot().currentUser.projectsLimit}}</strong> project limit.</p>
              <h5 class="card-title">Choose a Project</h5>
              <div class="list-group">
                <!--<button :class="classForProjectRow(project)" style="cursor:pointer;" v-for="project in appRoot().projects" v-bind:key="project.id" v-on:click="selectedProject = project">{{project.name}} <div class="float-end"><span class="fa fa-chevron-right"></span></div></button>-->
                <button :class="classForProjectRow(project)" style="cursor:pointer;" v-for="project in appRoot().projects" v-bind:key="project.id" v-on:click="selectedProject = project">
                  <PictureView class="float-start" :defaultValue='project.name[0].toUpperCase()' :imgURL='"/projects/" + project.id + "/icon"'></PictureView>
                  <div class="float-start" style="margin-left:15px; margin-top:10px;">{{project.name}}</div>
                  <div class="float-end" style="margin-top:10px;">
                    <span class="fa fa-chevron-right"></span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md"></div>
      </div>
    </template>
    <template v-if="loading == true">
      <div class="row">
        <div class="col-md"></div>
        <div class="col-md">
          <div class="card defaultMargin">
            <div class="card-header">
              Please Wait
            </div>
            <div class="card-body">
              <h5 class="card-title">Finding Your Projects</h5>
              <p class="card-text">Please wait, we're just searching for your projects...</p>
            </div>
          </div>
        </div>
        <div class="col-md"></div>
      </div>
    </template>
  </div>
</template>

<script>
import LogoView from '../../Components/LogoView.vue'
import PictureView from '../../Components/PictureView.vue'
import request from 'request';
export default {
  name: 'ProjectsList',
  components : {
    LogoView,
    PictureView
  },
  watch : {
    selectedProject : function(){
      window.appRoot.currentProject = this.selectedProject;
      this.$router.push('/projects/' + this.selectedProject.id);
    }
  },
  methods : {
    classForProjectRow : function(project) {
      if (this.selectedProject == null){
      return {
        "list-group-item" : true,
        "list-group-item-action" : true,
        "active" : false
      }
    }
    return {
      "list-group-item" : true,
      "list-group-item-action" : true,
      "active" : (project.id == this.selectedProject.id)
    }
    },
    appRoot : function(){
      return window.appRoot;
    },
    fetchProjects : function(){
      this.loading = true;
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects?skip=0&limit=50",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          scope.loading = false;
          if (response.statusCode != 200){

            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            if (jsonObject.projects != null){
              window.appRoot.projects = jsonObject.projects;
              return
            }
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      loading : false,
      selectedProject : null
    }
  },
  created : function(){
    this.fetchProjects();
  }
}
</script>
