import Vue from 'vue';
import VueRouter from 'vue-router';
import 'bootstrap';
import VueMeta from 'vue-meta'
require('bootstrap/dist/css/bootstrap.min.css');
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
//import "font-awesome-webpack";

let macchiatoWebVersionMajor = 1.0;
let macchiatoWebVersionMinor = 6;

window.maacchiatoLog = function(string) {
  console.log("Macchiato Web v" + macchiatoWebVersionMajor + "." + macchiatoWebVersionMinor + " - " + string);
};

window.__vue = Vue;
window.apiUrl = "https://api.macchiato.kappuccino.uk"
Vue.use(VueRouter);
Vue.use(VueMeta);

import VueAppleSignin from 'vue-apple-signin';

function makeid(length) {
   var result           = '';
   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

let signInWithAppleState = makeid(5);

Vue.use(VueAppleSignin, {
  clientId: 'uk.kAppuccino.macchiato.web',
  scope: 'email name',
  redirectURI: 'https://api.macchiato.kappuccino.uk/login/apple',
  state: signInWithAppleState,
});

import Home from './Pages/Home.vue';
import App from './App.vue';
import NewProject from './Pages/Home/NewProject.vue'
import ProjectsList from './Pages/Home/ProjectsList.vue'
import ProjectHomeView from './Pages/Home/ProjectHomeView.vue'
import NotificationsHomeView from './Pages/Notifications/NotificationsHomeView.vue'
import NotificationPreferencesView from './Pages/Home/Project/Preferences/NotificationPreferencesView.vue'
import ProjectUsageView from './Pages/Home/Project/Preferences/ProjectUsageView.vue'
import ProjectMenu from './Pages/Home/Project/ProjectMenu.vue'
import ProjectPreferencesRouter from './Pages/Home/Project/ProjectPreferencesRouter.vue'
import ProjectPreferencesMenu from './Pages/Home/Project/Preferences/ProjectPreferencesMenu.vue'
import ProjectGroupsRouter from './Pages/Home/Project/ProjectGroupsRouter.vue'
import NewProjectGroup from './Pages/Home/Project/Groups/NewProjectGroup.vue'
import ProjectGroupsView from './Pages/Home/Project/Groups/ProjectGroupsView.vue'
import ProjectGroupView from './Pages/Home/Project/Groups/ProjectGroupView.vue'
import ProjectTasks from './Pages/Home/Project/ProjectTasks.vue'
import ProjectMembersView from './Pages/Home/Project/Members/ProjectMembersView.vue'
import TaskList from './Pages/Home/Project/Tasks/TaskList.vue'
import TaskView from './Pages/Home/Project/Tasks/TaskView.vue'
import EditTask from './Pages/Home/Project/Tasks/EditTask.vue'
import NewTask from './Pages/Home/Project/Tasks/NewTask.vue'
import ProjectRequests from './Pages/Home/Project/ProjectRequests.vue'
import RequestView from './Pages/Home/Project/Requests/RequestView.vue'
import RequestList from './Pages/Home/Project/Requests/RequestList.vue'
import NewRequest from './Pages/Home/Project/Requests/NewRequest.vue'
import EditRequest from './Pages/Home/Project/Requests/EditRequest.vue'
import ProjectKanban from './Pages/Home/Project/Kanban/ProjectKanban.vue'
import Login from './Pages/Login.vue';
import LoginWelcome from './Pages/Login/Welcome.vue';
import LoginEmail from './Pages/Login/Email.vue';
import LoginRegister from './Pages/Login/Register.vue';
import ProjectTags from './Pages/Home/Project/ProjectTags.vue'
import TagList from './Pages/Home/Project/Tags/TagList.vue'
//import TagView from './Pages/Home/Project/Tags/TagView.vue'
import EditTag from './Pages/Home/Project/Tags/EditTag.vue'
import NewTag from './Pages/Home/Project/Tags/NewTag.vue'
import ProjectQueues from './Pages/Home/Project/ProjectQueues.vue'
import QueueList from './Pages/Home/Project/Queues/QueueList.vue'
import QueueTaskList from './Pages/Home/Project/Queues/QueueTaskList.vue'
import NewQueue from './Pages/Home/Project/Queues/NewQueue.vue'
// import About from './components/About.vue';
// import Contact from './components/Contact.vue';

const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/', component: Home, meta: { breadCrumb: 'Projects' }, children: [
      {
        path: '', component: ProjectsList, meta: { breadCrumb: 'Projects' }
      },
      {
        path: 'newproject', component: NewProject, meta: { breadCrumb: 'New Project' }
      },
      {
        path: 'projects', component: ProjectsList, meta: { breadCrumb: 'Projects' }
      },
      {
        path: 'notifications', component: NotificationsHomeView
      },
      {
        path: '/projects/:projectId', component: ProjectHomeView, meta: { breadCrumb: 'Project Menu' },
        children : [
          {
            path: '', component: ProjectMenu, name: "projectMenu", meta: { breadCrumb: 'Project Menu' }
          },
          {
            path: 'tasks', component: ProjectTasks, meta: { breadCrumb: 'Tasks' }, children: [
              {
                 path: '', component: TaskList, meta: { breadCrumb: 'Tasks' }
              },
              {
                 path: ':taskId', component: TaskView, meta: { breadCrumb: 'Task Details' }
              },
              {
                 path: ':taskId/edit', component: EditTask, meta: { breadCrumb: 'Edit Task' }
              }
            ]
          },
          {
            path: 'requests',component: ProjectRequests, meta: { breadCrumb: 'Requests' }, children: [
              {
                 path: '', component: RequestList, meta: { breadCrumb: 'Requests' }
              },
              {
                 path: ':requestId', component: RequestView, meta: { breadCrumb: 'Request Details' }
              },
              {
                 path: ':requestId/edit', component: EditRequest, meta: { breadCrumb: 'Edit Request' }
              }
            ]
          },
          {
            path: 'tags', component: ProjectTags, meta:{ breadCrumb: 'Tags' }, children: [
              {
                path: '', component: TagList, meta: { breadCrumb: 'Tags' }
              },
              {
                path: ':tagId/edit', component: EditTag, meta: { breadCrumb: 'Edit Tag'}
              }
            ]
          },
          {
            path: 'queues', component: ProjectQueues, meta:{ breadCrumb: 'Queues' }, children: [
              {
                 path: '', component: QueueList, meta: { breadCrumb: 'Queues' }
              },
              {
                 path: ':queueId', component: QueueTaskList, meta: { breadCrumb: 'Queue' }
              }
            ]
          },
          {
            path: 'newrequest',component: ProjectRequests, meta: { breadCrumb: 'New Request' }, children: [
              {
                 path: '', component: NewRequest, meta: { breadCrumb: 'New Request' }
              }
            ]
          },
          {
            path: 'newtask',component: ProjectTasks, meta: { breadCrumb: 'New Task' }, children: [
              {
                 path: '', component: NewTask, meta: { breadCrumb: 'New Task' }
              }
            ]
          },
          {
              path: 'newgroup',component: ProjectGroupsRouter, meta: { breadCrumb: 'New Group' }, children: [
                {
                   path: '', component: NewProjectGroup, meta: { breadCrumb: 'New Group' }
                }
              ]
          },
          {
            path: 'newtag',component: ProjectTags, meta: { breadCrumb: 'New Tag' }, children: [
              {
                 path: '', component: NewTag, meta: { breadCrumb: 'New Tag' }
              }
            ]
          },
          {
            path: 'newqueue',component: ProjectQueues, meta: { breadCrumb: 'New Queue' }, children: [
              {
                 path: '', component: NewQueue, meta: { breadCrumb: 'New Queue' }
              }
            ]
          },
          {
            path: 'kanban', component: ProjectKanban, meta: { breadCrumb: 'Kanban' }
          },
          {
            path: 'members', component: ProjectMembersView, meta: { breadCrumb: 'Members' }
          },
          {
            path: 'groups', component: ProjectGroupsRouter, meta: { breadCrumb: 'Groups' }, children: [
              {
                 path: '', component: ProjectGroupsView, name: "groupsView", meta: { breadCrumb: 'Groups' }
              },
              {
                 path: ':groupId', component: ProjectGroupView, meta: { breadCrumb: 'Group' }
              }
            ]
          },
          {
            path: 'preferences', component: ProjectPreferencesRouter, meta: { breadCrumb: 'Preferences' }, children: [
              {
                 path: '', component: ProjectPreferencesMenu, name: "preferenceMenu", meta: { breadCrumb: 'Preferences' }
              },
              {
                 path: 'notifications', component: NotificationPreferencesView, meta: { breadCrumb: 'Notifications' }
              },
              {
                 path: 'usage', component: ProjectUsageView, meta: { breadCrumb: 'Usage' }
              }
            ]
          }
        ]
      }
    ] },
    { path: '/login', component: Login, children: [
      {
         path: '', component: LoginWelcome
      },
      {
         path: 'email', component: LoginEmail
      },
      {
         path: 'register', component: LoginRegister
      }
    ]},
    { path: '/contact', component: Home }
  ]
});

new Vue({
  router : router,
  render: function(createElement){
    window.appRoot = createElement(App);
    console.log("Created App Root.");
    console.log(window.appRoot.notifications);
    return window.appRoot;
  }
}).$mount('#app');
