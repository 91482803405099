<template>
  <div>
    <!-- <div class="row" v-if="showLogo">
      <div class="col-md"></div>
      <div class="col-md"><LogoView/></div>
      <div class="col-md"></div>
    </div> -->
    <template v-if="loading == false && projectStatus == 200 && !useSplitScreen()">
      <Crumbs></Crumbs>
      <router-view @hidelogo="showLogo = false" @showlogo="showLogo = true"></router-view>
    </template>
    <template v-if="loading == false && projectStatus == 200 && useSplitScreen()">
      <div class="row">
        <template v-if="showMenu == true">
          <div class="col-md-3">
            <ProjectMenu></ProjectMenu>
          </div>
          <div class="col-md-9">
            <Crumbs style="margin:14px;"></Crumbs>
            <router-view @hidelogo="showLogo = false" @showlogo="showLogo = true"></router-view>
          </div>
        </template>
        <template v-if="showMenu==false">
          <div class="col-md-12">
            <Crumbs style="margin:14px;"></Crumbs>
            <router-view @hidelogo="showLogo = false" @showlogo="showLogo = true"></router-view>
          </div>
        </template>
      </div>
    </template>
    <template v-if="loading == false && projectStatus == 401 && !accessRequested">
      <div class="row">
        <div class="col-md"></div>
        <div class="col-md">
          <div class="card defaultMargin">
            <div class="card-header">
              {{projectName}}
            </div>
            <div class="card-body">
              <p class="card-text">You do not have permission to view this project.</p>
              <div class="btn btn-primary" @click="requestAccess">Request Access</div>
            </div>
          </div>
        </div>
        <div class="col-md"></div>
      </div>
    </template>
    <template v-if="loading == false && projectStatus == 401 && accessRequested">
      <div class="row">
        <div class="col-md"></div>
        <div class="col-md">
          <div class="card defaultMargin">
            <div class="card-header">
              {{projectName}}
            </div>
            <div class="card-body">
              <p class="card-text"><strong>Your request has been received and the relevant users have been notified.</strong><br/>
                You will receive a notification if your request is approved.<br/>
                You can close this page or click <a href="/">here</a> to go to your project list.
              </p>
            </div>
          </div>
        </div>
        <div class="col-md"></div>
      </div>
    </template>


    <template v-if="loading == false && error != null">
      <div class="row">
        <div class="col-md"></div>
        <div class="col-md">
          <div class="card defaultMargin">
            <div class="card-header">
              Error
            </div>
            <div class="card-body">
              <p class="card-text">{{error}}</p>
            </div>
          </div>
        </div>
        <div class="col-md"></div>
      </div>
    </template>


    <template v-if="loading == false && projectStatus == 404">
      <div class="row">
        <div class="col-md"></div>
        <div class="col-md">
          <div class="card defaultMargin">
            <div class="card-header">
              Project Not Found
            </div>
            <div class="card-body">
              <p class="card-text">We couldn't find this project, it may have been deleted.</p>
            </div>
          </div>
        </div>
        <div class="col-md"></div>
      </div>
    </template>


    <template v-if="loading == true">
      <div class="row">
        <div class="col-md"></div>
        <div class="col-md">
          <div class="card defaultMargin">
            <div class="card-header">
              Please Wait
            </div>
            <div class="card-body">
              <h5 class="card-title">Fetching Project</h5>
              <p class="card-text">Please wait, we're just asking for more project information...</p>
            </div>
          </div>
        </div>
        <div class="col-md"></div>
      </div>
    </template>
    <TipsModal v-if="showTips" @dismiss="showTips = false"></TipsModal>
  </div>
</template>

<script>
//import LogoView from '../../Components/LogoView.vue'
import Crumbs from '../../Components/Crumbs.vue'
import ProjectMenu from './Project/ProjectMenu.vue';
import TipsModal from '../../Components/TipsModal.vue';
import request from 'request';
export default {
  name: 'ProjectHomeView',
  components : {
  //  LogoView,
  Crumbs,
  ProjectMenu,
  TipsModal
  },
  watch : {
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    useSplitScreen : function(){
      console.log(window.innerWidth);
      return this.$route.name != "projectMenu" && window.innerWidth > 1500;
    },
    requestAccess : function(){
      this.loading = true;
      this.accessRequested = false;
      this.error = null;
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/access",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response){
          scope.loading = false;

          if (response.statusCode != 200){
            scope.error = "An error occured whilst processing your request, please try again later.";
            return
          }
          scope.accessRequested = true;
        });
      }
      fetchWith(this);
    },
    fetchProject : function(){
      this.loading = true;
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          scope.loading = false;
          scope.projectStatus = response.statusCode;

          var jsonObject = JSON.parse(data);
          if (response.statusCode != 200){
            if (jsonObject != null){
                document.title = jsonObject.name;
                scope.projectName = jsonObject.name;
            }
            return
          }
          if (error != null){
            return
          }
          if (jsonObject != null){
            if (jsonObject.id != null){
              window.appRoot.currentProject = jsonObject;
              document.title = jsonObject.name;
              scope.fetchPermissions();
              return
            }
          }
        });
      }
      fetchWith(this);
    },
    fetchPermissions : function(){
      this.loading = true;
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/permissions/me",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          scope.loading = false;
          if (response.statusCode != 200){

            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            if (jsonObject.permissions != null){
              window.appRoot.currentPermissions = jsonObject.permissions;
              return
            }
          }
        });
      }
      fetchWith(this);
    },
    toggleMenu : function(){
      console.log(this.showMenu);
      if (this.showMenu == true){
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
      console.log(this.showMenu);
      return
    }
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        { vmid: 'apple-itunes-app', name: 'apple-itunes-app', content:  "app-id=1529092126" + this.$route.params.projectId }
      ]
    }
  },
  data : function(){
    return {
      loading : false,
      projectStatus : null,
      projectName : null,
      showLogo : true,
      showTips : false,
      error : null,
      accessRequested : false,
      showMenu : true
    }
  },
  created : function(){
    if (this.$route.query.tips == "true") {
      this.showTips = true;
    }
    this.fetchProject();

    var self = this;
    this.$root.$on('toggleMenu', () => {
      console.log("Toggling the menu.");
      self.toggleMenu();
    });
  }
}
</script>
<style scoped>
.auto-grid {
  --auto-grid-min-size: 12rem;
  list-style:none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 1rem;
  padding-left:0;
}
</style>
