<template>
  <div>
    <div v-if="!loading" style="display:grid;">
      <div class="input-group mb-3">
        <input type="text" class="form-control" :placeholder="placeholder()" v-model="newMessage" :disabled="request.closed">
        <button class="btn btn-outline-secondary" type="button" v-on:click="createDiscussion()"  v-if="newMessage != '' && !sending">Send</button>
        <button  v-if="sending" class="btn btn-outline-secondary" type="button" disabled><span class="fa fa-spinner fa-spin"></span></button>
      </div>
      <DiscussionBlock  v-for="discussion in discussions" :discussion="discussion" v-bind:key="discussion.id"></DiscussionBlock>
    </div>
    <div v-if="loading">
      <h5>Please Wait</h5>
      <p>Fetching comments...</p>
      <div>
        <span class="fa fa-spinner fa-spin"></span>
      </div>
    </div>
  </div>
</template>

<script>
import DiscussionBlock from '../../../../Components/DiscussionBlock.vue'
import request from 'request';
export default {
  name: 'RequestDiscussionView',
  props : ["request"],
  components : {
    DiscussionBlock
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    placeholder : function(){
      if (this.request.closed != true) {
        return "Type here to comment...";
      }
      return "Re-open this request to comment."
    },
    iconFor : function(request){
      var classDict = {
        "fas" : true,
        "fa-2x" : true,
        "float-end" : true
      }
      switch (request.priority) {
        case 5:
        classDict["fa-angle-double-up"] = true;
        classDict["text-danger"] = true;
        break;
        case 4:
        classDict["fa-angle-up"] = true;
        classDict["text-warning"] = true;
        break;
        case 3:
        classDict["fa-angle-right"] = true;
        classDict["text-default"] = true;
        break;
        case 2:
        classDict["fa-angle-down"] = true;
        classDict["text-primary"] = true;
        break;
        case 1:
        classDict["fa-angle-double-down"] = true;
        classDict["text-success"] = true;
        break;
        default:
        break;
      }
      return classDict;
    },
    createDiscussion : function(){
      var createWith = function(scope) {
        scope.sending = true;
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/requests/" + scope.$route.params.requestId + "/discussion",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          json: {
            message : scope.newMessage
          },
          method : "POST"
        }, function(error, response){
          scope.sending = false;
          if (response.statusCode != 201){
            return
          }
          scope.newMessage = "";
          scope.fetchRequestDetails();
        });
      }
      createWith(this);
    },
    fetchRequestDetails : function(){
      var fetchWith = function(scope) {
        scope.loading = true;
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/requests/" + scope.$route.params.requestId + "/discussion",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          console.log(jsonObject);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.discussions = jsonObject.discussions;
            scope.discussions.sort(function(d1, d2) {
              if (d1.createdAt < d2.createdAt) {
                return 1;
              }
              return -1;
            });
            return
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      discussions : null,
      loading : false,
      sending : false,
      newMessage : ""
    }
  },
  created : function(){
    this.fetchRequestDetails();
  }
}
</script>
<style>
</style>
