<template>
  <div class="container">
    <br/>
       <div class="card defaultMargin">
        <div class="card-header customFont d-flex justify-content-between align-items-center">
          <h5>New Project</h5>
        </div>
        <div class="card-body text-start">
          <div class="mb-3">
            <label class="form-label">Project Name</label>
            <input type="text" class="form-control" v-model="name">
            <p class="text-muted">Your project name must be unique (to you) and at-least 6 characters long.</p>
          </div>
          <div class="mb-3">
            <label class="form-label">Choose a project template</label>
            <select class="form-select" v-model="selectedTemplate">
                  <option :value="template" v-for="template in templates" v-bind:key="template.name">{{template.name}}</option>
            </select>
          </div>
          <div class="mb-3" v-if="selectedTemplate != null">
            <p>Tags</p>
            <ul>
              <li v-for="tag in selectedTemplate.tags" v-bind:key="tag.title">{{tag.title}}</li>
              <li v-if="selectedTemplate.tags.length == 0">No tags will be created for this template.</li>
            </ul>
            <p>Queues</p>
            <ul>
              <li v-for="queue in selectedTemplate.queues" v-bind:key="queue.name">{{queue.name}}</li>
              <li v-if="selectedTemplate.queues.length == 0">No queues will be created for this template.</li>
            </ul>
          </div>
          <div class="mb-3">
              <a href="/"><div class="btn btn-warning float-start">Cancel</div></a>
            <div class="btn btn-primary float-end" @click="createProject()">Create</div>
          </div>
        </div>
      </div>
    <AlertModal
       v-if="error != null"
       title="Error"
       :description="error"
       cancelText="Ok"
       @dismiss="error = null">
     </AlertModal>
     <AlertModal
        v-if="loadingActivity != null"
        title="Please Wait"
        :description="loadingActivity">
      </AlertModal>
</div>
</template>

<script>
import AlertModal from '../../Components/AlertModal.vue'
import request from 'request';
export default {
  name: 'NewProject',
  components : {
    AlertModal
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    createTags : function(projectId){
      this.loadingActivity = "Creating your tags...";
      var pushWith = function(scope) {


        var i = 0;
        function processNextTag() {

          if (i >= scope.selectedTemplate.tags.length) {
            window.location.href = "/projects/" + projectId + "?tips=true";
            return
          }
          scope.loadingActivity = "Creating " + (i + 1) + " of " + scope.selectedTemplate.tags.length + " tags...";

          request({
            url: window.apiUrl + "/projects/" + projectId + "/tags",
            json : scope.selectedTemplate.tags[i],
            headers : {
              "x-token-type" : "JWT"
            },
            auth : {
              "bearer" : window.localStorage.getItem('macchiato-token')
            },
            method : "POST"
          }, function(){
            i++;
            processNextTag();
          });
        }
        processNextTag();
      }
      pushWith(this);
    },
    createQueues : function(projectId){
      this.loadingActivity = "Creating your queues...";
      var pushWith = function(scope) {


        var i = 0;
        function processNextQueue() {

          if (i >= scope.selectedTemplate.queues.length) {
            scope.createTags(projectId);
            return
          }
          scope.loadingActivity = "Creating " + (i + 1) + " of " + scope.selectedTemplate.queues.length + " queues...";

          request({
            url: window.apiUrl + "/projects/" + projectId + "/queues",
            json : scope.selectedTemplate.queues[i],
            headers : {
              "x-token-type" : "JWT"
            },
            auth : {
              "bearer" : window.localStorage.getItem('macchiato-token')
            },
            method : "POST"
          }, function(){
            i++;
            processNextQueue();
          });
        }
        processNextQueue();
      }
      pushWith(this);
    },
    createProject : function(){
      this.loadingActivity = null;
      if (this.name == null) {
        this.error = "Please enter a project name that's at-least 6 characters long.";
        return
      }
      if (this.name.length < 6) {
        this.error = "Please enter a project name that's at-least 6 characters long.";
        return
      }

      this.loadingActivity = "We are creating your project, please wait.";
      var fetchWith = function(scope) {

        request({
          url: window.apiUrl + "/projects",
          json : {
            name : scope.name
          },
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "POST"
        }, function(error, response, json){
          scope.loadingActivity = null;
          if (error != null){
            scope.error = error;
            return
          }
          if (json != null){
            //Navigate to that project using the new identifier created...
            if (json.id != null){
            scope.createQueues(json.id);
            return
            }
              scope.error = json.error ?? "An issue occured when trying to create your project. Please try again and make sure you don't already have a project with this name.";
              return
          }
          scope.error = "An issue occured when trying to create your project. Please try again and make sure you don't already have a project with this name."
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      name : null,
      error : null,
      loadingActivity : null,
      selectedTemplate : null,
      templates : [
        {
          name : "Blank",
          description : "No pre-defined queues or tags.",
          queues : [],
          tags : []
        },
        {
          name : "Basic",
          description : "For all basic starter projects.",
          queues : [
            {
              name : "ToDo",
              hexColor : "#F55442",
              sortIndex : 0
            },
            {
              name : "Doing",
              hexColor : "#FFB545",
              sortIndex : 1
            },
            {
              name : "Done",
              hexColor : "#3AE85A",
              sortIndex : 2
            }
          ],
          tags : [
            {
              title : "Home",
              description : "",
              hexColor : "#F55442"
            },
            {
              title : "Work",
              description : "",
              hexColor : "#3AE85A"
            },
            {
              title : "Fitness",
              description : "",
              hexColor : "#45AEFF"
            },
            {
              title : "Finance",
              description : "",
              hexColor : "#3AE85A"
            },
            {
              title : "Cleaning",
              description : "",
              hexColor : "#FF45B5"
            }
          ]
        },
        {
          name : "Software Development",
          description : "Your software development starter!",
          queues : [
            {
              name : "ToDo",
              hexColor : "#F55442",
              sortIndex : 0
            },
            {
              name : "Doing",
              hexColor : "#FFB545",
              sortIndex : 1
            },
            {
              name : "UAT",
              hexColor : "#FFF945",
              sortIndex : 2
            },
            {
              name : "Beta Testing",
              hexColor : "#45AEFF",
              sortIndex : 3
            },
            {
              name : "Released",
              hexColor : "#FF45B5",
              sortIndex : 4
            }
          ],
          tags : [
            {
              title : "Bug",
              description : "",
              hexColor : "#F55442"
            },
            {
              title : "Feature Request",
              description : "",
              hexColor : "#FFF945"
            },
            {
              title : "Beta Related",
              description : "",
              hexColor : "#45AEFF"
            },
            {
              title : "Crash",
              description : "",
              hexColor : "#3AE85A"
            }
          ]
        }
      ]
    }
  },
  created : function(){
    this.selectedTemplate = this.templates[0];
  }
}
</script>
