<template>
  <li class="gridTile" :style="tileStyle()" @click="$emit('click')">
    <div class="full-width defaultPadding">
      <div class="float-start">
        <span :class="iconClass()"></span>
      </div>
      <div class="float-end" v-if="count != null">
        <span>{{count}}</span>
      </div>
    </div>
    <br/>
    <div class="full-width text-start defaultPadding" style="margin-top:4px;">
      <h4>{{title}}</h4>
    <p style="line-height: 20px;">{{description}}</p>
    </div>
  </li>
</template>

<script>
export default {
  name: 'GridTile',
  props : ['title','description','count','icon','tint'],
  methods : {
    iconClass : function(){
      var classDict = {
        "fas" : true,
        "fa-2x" : true
      }
      classDict[this.icon] = true;
      return classDict;
    },
    tileStyle : function(){
      var styleDict = {
        "cursor" : "pointer",
        "background-color" : this.tint
      }
      return styleDict;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.gridTile {
  height:150px;
  border-radius: 8px;
  line-height: 8px;
  color: #fff;
}
</style>
