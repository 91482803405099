<template>
  <div>
    <h1 class="customFont">New Account</h1>
    <div class="container text-start">
      <div class="mb-3">
        <label class="form-label">First Name</label>
        <input type="text" class="form-control" v-model="firstName">
        <div class="form-text text-muted">We need this to identify you when you collaborate on projects.</div>
      </div>
      <div class="mb-3">
        <label class="form-label">Last Name(s)</label>
        <input type="text" class="form-control" v-model="lastName">
        <div class="form-text text-muted">We need this to identify you when you collaborate on projects.</div>
      </div>
      <div class="mb-3">
        <label class="form-label">Email address</label>
        <input type="email" class="form-control" v-model="email">
        <div class="form-text">We'll never share your email with anyone else.</div>
      </div>
      <div class="mb-3">
        <label class="form-label">Confirm Email address</label>
        <input type="email" class="form-control" v-model="confirmEmail">
      </div>
      <div class="mb-3">
        <label class="form-label">Password</label>
        <input type="password" class="form-control" v-model="password">
      </div>
      <div class="mb-3">
        <label class="form-label">Confirm Password</label>
        <input type="password" class="form-control" v-model="confirmPassword">
      </div>
      <h5 class="text-danger">{{error}}</h5>
      <button class="btn btn-primary float-end" v-if="!loading" @click="createAccount()">Create Account</button>
      <div class="float-end" v-if="loading"><span class="fa fa-spinner fa-spin"></span></div>
      <br/><br/>
    </div>
  </div>
</template>

<script>
import request from 'request';
export default {
  name: 'LoginRegister',
  methods : {
    attemptLogin : function(){
      var self = this;
      self.loading = true;
      self.error = "";
      request({
        url: window.apiUrl + "/login",
        json : {
          "email" : self.email,
          "password" : self.password
        },
        method : "POST"
      }, function(error, response, json){
        self.loading = false;
        if (response.statusCode != 200){
          self.error = "Your account was created but an error occured when trying to sign you in."
          return
        }
        if (error != null){
          self.error = error;
          return
        }
        if (json != null){
          //Navigate to that task using the new identifier created...
          window.location.href = "/?token=" + json.jwt;
          return
        }
        self.error = "Your account was created but an error occured when trying to sign you in."
      });
    },
    createAccount : function(){
      var self = this;
      if (self.firstName == "" || self.firstName == null) { return; }
      if (self.lastName == "" || self.lastName == null) { return; }
      if (self.email == "" || self.email == null) { return; }
      if (self.confirmEmail == "" || self.confirmEmail == null) { return; }
      if (self.password == "" || self.password == null) { return; }
      if (self.confirmPassword == "" || self.confirmPassword == null) { return; }
      if (self.email != self.confirmEmail) { return; }
      if (self.password != self.confirmPassword) { return; }

      self.loading = true;
      self.error = "";
      request({
        url: window.apiUrl + "/register",
        json : {
          "email" : self.email,
          "password" : self.password,
          "firstName" : self.firstName,
          "lastName" : self.lastName
        },
        method : "POST"
      }, function(error, response, json){
        self.loading = false;
        if (error != null){
          self.error = error;
          return
        }
        if (json != null){
          //Navigate to that task using the new identifier created...
          if (json.id != null){
            self.attemptLogin();
          }
          return
        }
        self.error = "Check your details and try again, an account might already exist with this email."
      });
    }
  },
  data : function(){
    return  {
      loading : false,
      error : "",
      email : "",
      confirmEmail : "",
      password : "",
      confirmPassword : "",
      firstName : "",
      lastName : ""
    }
  }
}
</script>
