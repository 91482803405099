<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
    <a class="navbar-brand customFont float-start" href="/">
      <LogoView style="max-height:32.5px;max-width:32.5px;margin-right:-8px;margin-bottom:4px;"></LogoView>
      acchiato
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" style="margin-right:5px;" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <div style="float:left;cursor:pointer;">
          <PictureView @click.native="showUpload=true" :defaultValue='currentUser.firstName[0].toUpperCase() + currentUser.lastName[0].toUpperCase()' :imgURL='"/users/" + currentUser.id + "/profile-picture"'></PictureView>
        </div>
        <li class="nav-item dropdown">

        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          {{greetingMessage}} {{upperCaseFirst(currentUser.firstName)}} {{upperCaseFirst(currentUser.lastName)}} <span class="badge bg-secondary" v-if="notificationCount > 0">{{notificationCount}}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-md-end" aria-labelledby="navbarDropdown">
          
          <li><a class="dropdown-item" href="/notifications">Notifications <span class="badge bg-secondary" v-if="notificationCount > 0">{{notificationCount}}</span></a></li>
          <li><a class="dropdown-item" href="#">Account Settings</a></li>
          <li><hr class="dropdown-divider"></li>
          <li class="dropdown-item text-danger" @click="logout()">Logout</li>
        </ul>
        </li>
      </ul>
    </div>

  <FileUploader v-if="showUpload" uploadType="profile-pic" @dismiss="toggleUpload()">
    <h2>Upload your profile picture</h2>
  </FileUploader>

 </div>
  </nav>
</template>

<script>
import LogoView from './LogoView.vue'
import PictureView from './PictureView.vue'
import FileUploader from './FileUploader.vue'
export default {
  name: 'NavigationBar',
  computed : {
    currentUser : function() {
      return window.appRoot.currentUser;
    }
  },
  methods : {
    upperCaseFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    logout: function(){
      window.localStorage.removeItem("macchiato-token");
      window.location.reload();
    },
    toggleUpload: function(){
      this.showUpload = !this.showUpload
    },
    greeting: function(){
      var today = new Date();
      console.log("today is " + today);

      var hours = today.getHours();
      console.log("hours is " + hours);

      if(hours >= 0 && hours < 12){
        this.greetingMessage = "Good Morning,";
      } else if (hours >= 12 && hours < 18){
        this.greetingMessage = "Good Afternoon,";
      } else if (hours >= 18 && hours < 20){
        this.greetingMessage = "Good Evening,";
      } else {
        this.greetingMessage = "Hello,";
      }

      console.log("Greeting message " + this.greetingMessage);
    }
  },
  components : {
    LogoView,
    PictureView,
    FileUploader
  },
  data : function() {
    return {
      notificationCount : 0,
      showUpload : false,
      pictureHover : false,
      greetingMessage : "Hello,"
    }
  },
  created : function() {
    var self = this;
    this.$root.$on('notificationCount', (count) => {
      self.notificationCount = count;
    });
    self.greeting();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
