<template>
  <div class="kanbanqueue">
    <div class="card card-block">
      <div class="card-header customFont text-white" :style="headerStyle">
        <button v-show="!showFilters" class="btn btn-sm text-white float-start" @click="showFilters = true">
          <i class="fa fa-filter"></i>
        </button>
        <button v-show="showFilters" class="btn btn-sm text-white float-start" @click="showFilters = false">
          <i class="fa fa-times"></i>
        </button>
        {{title}}
        <button class="btn btn-sm text-white float-end" @click="newTask()">
          <i class="fa fa-plus"></i>
        </button>
      </div>
      <div class="card-body">
  <div :style="containerStyle()">
    <TaskFilterView v-if="showFilters" :filters="filters" @hide="showFilters = false"></TaskFilterView>
        <hr v-if="showFilters"/>
        <draggable v-model="tasks" group="tasks" @change="dragDidChange" draggable=".dragItem">
            <div v-for="task in tasks" class="row dragItem" style="cursor: pointer;"  v-bind:key="task.id + '_' + filters.queueId" v-on:click="openTask(task.id)">
              <div class="col-sm-10 text-start">
                <p>{{task.identifier}}</p>
                <h5>{{task.title}}</h5>
                <!--<p>{{truncate(task.detailedDescription)}}</p>-->
                <!--<p></p>-->
                <TagsView :tags="task.tags" :parentId="task.id" style="margin-top:20px"></TagsView>
              </div>
              <div class="col-sm-2 text-end">
                <div class="row">
                <div class="col-lg text-end" v-if="task.assignedTo != null">
                  <!--<h5 class="userOrb">
                    {{task.assignedTo.firstName[0].toUpperCase()}}{{task.assignedTo.lastName[0].toUpperCase()}}
                  </h5>-->
                  <!--<PictureView class="float-start" :defaultValue='task.assignedTo.firstName[0].toUpperCase() + task.assignedTo.lastName[0].toUpperCase()' :userId='task.assignedTo.id'></PictureView>-->
                  <PictureView class="float-start" :defaultValue='task.assignedTo.firstName[0].toUpperCase() + task.assignedTo.lastName[0].toUpperCase()' :imgURL='"/users/" + task.assignedTo.id + "/profile-picture"'></PictureView>
                </div>
                <div class="col-lg text-end">
                  <p class="float-end">
                    <span :class="iconFor(task)"></span>
                  </p>
                </div>
              </div>
              </div>
              <hr v-bind:key="task.id + 'line'"/>
            </div>
            <div class="row text-muted text-start" v-if="tasks.length == 0">
              <h5>Empty</h5>
              <p>This queue is empty. Drop a task here to populate it.</p>
            </div>
          <div class="row" slot="footer" v-if="canLoadMore">
            <div class="col text-center" style="cursor:pointer;" @click="loadMore">
              <h5 style="font-weight:bold;color:rgb(18,153,226);">Load More</h5>
            </div>
          </div>

        </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import TagsView from '../../../../Components/TagsView.vue';
import request from 'request';
import TaskFilterView from '../../../../Components/TaskFilterView.vue';
import PictureView from '../../../../Components/PictureView.vue'
export default {
  name: 'KanbanList',
  components : {
    TagsView,
    draggable,
    TaskFilterView,
    PictureView
  },
  props : ["filters","updateHandler","title","headerStyle"],
  watch : {
    filters : {
      handler : function() {
        this.skip  = 0;
        this.tasks = [];
        this.fetchProjectTasks();
      },
      deep: true
    }
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    newTask : function(){
          window.location = "/projects/" + this.$route.params.projectId + "/newtask";
    },
    truncate : function(text) {
      if (text.length > 150) {
        return text.slice(0,150) + "...";
      }
      return text;
    },
    containerStyle : function(){
      return {
        "max-height" : (window.innerHeight - 320) + "px !important",
        "height" : (window.innerHeight - 320) + "px !important",
        "padding-right" : "20px"
      }
    },
    dragDidChange: function(evt) {
      console.log(evt);
      if (evt.added != null){
        if (evt.added.element != null){
          if (evt.added.element.id != null) {
            let taskId = evt.added.element.id;
            this.updateProjectTask(taskId);
          }
        }
      }
    },
    updateProjectTask : function(taskId){
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tasks/" + taskId,
          // json : {
          //   queueId : scope.filters.queueId
          // },
          json : scope.updateHandler(scope.filters.queueId),
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "PUT"
        }, function(error, response){
          if (response.statusCode != 200){
            return
          }
        });
      }
      fetchWith(this);
    },
    openTask : function(taskId) {
      window.location.href = this.taskLink(taskId);
    },
    taskLink : function(taskId){
      return "/projects/" + this.$route.params.projectId + "/tasks/" + taskId;
    },
    loadMore : function(){
      this.skip += this.limit;
      this.fetchProjectTasks();
    },
    iconFor : function(task){
      var classDict = {
        "fas" : true,
        "fa-2x" : true,
        "float-end" : true
      }
      switch (task.priority) {
        case 5:
        classDict["fa-angle-double-up"] = true;
        classDict["text-danger"] = true;
        break;
        case 4:
        classDict["fa-angle-up"] = true;
        classDict["text-warning"] = true;
        break;
        case 3:
        classDict["fa-angle-right"] = true;
        classDict["text-default"] = true;
        break;
        case 2:
        classDict["fa-angle-down"] = true;
        classDict["text-primary"] = true;
        break;
        case 1:
        classDict["fa-angle-double-down"] = true;
        classDict["text-success"] = true;
        break;
        default:
        break;
      }
      return classDict;
    },
    fetchProjectTasks : function(){
      var fetchWith = function(scope) {
        scope.canLoadMore = false;
        var params = scope.filters;
        //inject non-filter params
        params["limit"] = scope.limit;
        params["offset"] = scope.skip;

        var cleanParams = {}; //Remove NULL values that cause issues... If the filter value is NULL then just don't include it at all in the request.
        for (var key in params) {
          if (params[key] != null){
            cleanParams[key] = params[key];
          }
        }
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tasks",
          qs : cleanParams,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.tasks = scope.tasks.concat(jsonObject.tasks);
            if (jsonObject.tasks.length == scope.limit){
              scope.canLoadMore = true;
            }
            return
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      tasks : [],
      showFilters : false,
      canLoadMore : false,
      skip : 0,
      limit : 8
    }
  },
  created : function(){
    this.fetchProjectTasks();
  }
}
</script>
<style scoped>
.userOrb {
  font-size: 16px;
  background-color:rgb(18,153,226);
  border-radius:20px;
  width:40px;
  height:40px;
  color: white;
  text-align: center;
  vertical-align: middle;
  line-height:40px;
}

.ontop-left {
  position:absolute;
  left:10;
}

a.nostyle:link {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
}

a.nostyle:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
}
</style>
