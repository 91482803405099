<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb bg-light">
      <li :class="classFor(crumb)" v-for="crumb in breadCrumbs" v-bind:key="crumb.text">
        <a v-if="crumb.disabled != true" :href="crumb.href">{{crumb.text}}</a>
        <template  v-if="crumb.disabled == true">{{crumb.text}}</template>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'Crumbs',
  methods : {
    classFor: function(crumb) {
      return {
        "breadcrumb-item" : true,
        "active" : crumb.disabled == true
      }
    }
  },
  computed: {
    breadCrumbs () {
      let pathArray = this.$route.path.split('/')
      pathArray.shift()
      const breadCrumbs = []
      let breadcrumb = ''
      let lastIndexFound = 0
      for (let i = 0; i < pathArray.length; ++i) {
        breadcrumb = `${breadcrumb}${'/'}${pathArray[i]}`
        if (this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], 'meta') &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, 'breadCrumb')) {
          breadCrumbs.push({
            href: i !== 0 && pathArray[i - (i - lastIndexFound)]
              ? breadcrumb
              : breadcrumb,
            disabled: i + 1 === pathArray.length,
            text: this.$route.matched[i].meta.breadCrumb || pathArray[i]
          })
          lastIndexFound = i
          //breadcrumb = ''
        }
      }
      return breadCrumbs
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ol.breadcrumb {
  padding: 16px;
  border-radius:6px;
}
</style>
