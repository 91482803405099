<template>
<div class="card defaultMargin">
  <div class="card-header customFont">
    <div class="float-start row row-cols-auto">
      <div class="col">
        <button class="btn" @click="showHideMenu();"><i class="fa fa-bars"></i></button>
      </div>
      <div class="col">
        <h5>{{appRoot().currentProject.name}} - Kanban</h5>
      </div>
    </div>
    <div class="btn-group float-end">
      <button :class="toggleClassFor('All')" @click="taskType = 'All';fetchProjectQueues();">All Tasks</button>
      <button :class="toggleClassFor('Mine')" @click="taskType = 'Mine';fetchProjectQueues();">My Tasks</button>
    </div>
  </div>
  <div class="card-body scrollable">
    <div class="container-fluid">
      <div class="row flex-row flex-nowrap auto-grid">
        <KanbanList
          :filters="backlogFilters"
          :updateHandler="backlogUpdateHandler"
          title="Backlog"
          class="kanbanlist"
          :headerStyle="blacklogHeaderStyle">
        </KanbanList>
        <KanbanList
          v-for="queue in queues"
          v-bind:key="queue.id"
          :filters="queue.filters"
          :updateHandler="queueUpdateHandler"
          :title="queue.name"
          class="kanbanlist"
          :headerStyle="styleForQueue(queue)">
        </KanbanList>
        <KanbanList
          :filters="closedFilters"
          :updateHandler="closedUpdateHandler"
          title="Closed"
          class="kanbanlist"
          :headerStyle="closedHeaderStyle">
        </KanbanList>
      </div>

    </div>
  </div>
</div>
</template>

<script>
import KanbanList from '../Kanban/KanbanList.vue'
import request from 'request';
export default {
  name: 'ProjectTasks',
  components: {
    KanbanList
  },
  watch: {},
  methods: {
    appRoot: function() {
      return window.appRoot;
    },
    goBack: function() {
      this.$router.back();
    },
    backlogUpdateHandler: function() {
      return {
        "queueId": ""
      }
    },
    closedUpdateHandler: function() {
      return {
        "closed": true
      }
    },
    queueUpdateHandler: function(queueId) {
      return {
        "queueId": queueId
      }
    },
    toggleClassFor: function(type) {
      if (this.taskType == type) {
        return {
          "btn": true,
          "btn-sm": true,
          "bg-primary": true,
          "text-white": true
        }
      } else {
        return {
          "btn": true,
          "btn-sm": true,
          "bg-secondary": true,
          "text-white": true
        }
      }
    },
    styleForQueue: function(queue) {
      return {
        "background-color": queue.hexColor
      }
    },
    fetchProjectQueues: function() {
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/queues",
          headers: {
            "x-token-type": "JWT"
          },
          auth: {
            "bearer": window.localStorage.getItem('macchiato-token')
          },
          method: "GET"
        }, function(error, response, data) {
          if (response.statusCode != 200) {
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null) {
            return
          }
          if (jsonObject != null) {
            for (var index in jsonObject.queues) {
              jsonObject.queues[index].filters = {
                sortBy: "priority",
                sortOrder: "DESC",
                title: "",
                queueId: jsonObject.queues[index].id,
                detailedDescription: "",
                closed: false,
                assignedTo: scope.taskType == 'Mine' ? window.appRoot.currentUser.id : null
              }
            }
            scope.closedFilters["assignedTo"] = scope.taskType == 'Mine' ? window.appRoot.currentUser.id : null;
            scope.closedFilters = Object.assign({}, scope.closedFilters);
            scope.queues = jsonObject.queues;
            return
          }
        });
      }
      fetchWith(this);
    },
    showHideMenu: function() {
      console.log('button pressed');
      this.$root.$emit("toggleMenu");
    },
    handleResize: function(){
      this.$forceUpdate();
    }
  },
  data: function() {
    return {
      queues: [],
      closedFilters: {
        sortBy: "priority",
        sortOrder: "DESC",
        title: "",
        detailedDescription: "",
        closed: true
      },
      backlogFilters: {
        sortBy: "priority",
        sortOrder: "DESC",
        title: "",
        detailedDescription: "",
        closed: false,
        queueId: "$EMPTY"
      },
      closedHeaderStyle: {
        "background-color": "rgb(220, 53, 69)"
      },
      blacklogHeaderStyle: {
        "background-color": "#212529"
      },
      taskType: "All"
    }
  },
  beforeDestroy() {
    this.$emit('showlogo');
    window.removeEventListener('resize', this.handleResize);
  },
  created: function() {
    this.$emit('hidelogo');
    this.fetchProjectQueues();
    window.addEventListener('resize', this.handleResize);
  }
}
</script>
<style scoped>
.ontop-left {
  position: absolute;
  left: 10;
}

.auto-grid {
  --auto-grid-min-size: 200px;
  list-style: none;
  grid-gap: 1rem;
  padding-left: 0;
}

.kanbanqueue {
  min-width: 400px;
  max-width: 400px;
}

.scrollable {
  overflow-x: scroll;
}

.kanbanlist {
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
