<template>
  <div>
  <TagDetailView :tag="tagToView" v-if="tagToView != null" @dismiss="tagToView = null"></TagDetailView>
  <template v-if="tagToView == null">
      <div class="card defaultMargin">
        <div class="card-header customFont d-flex justify-content-between align-items-center">
          <h5>{{appRoot().currentProject.name}} - Tags</h5>
          <a href="newtag"><div class="btn btn-sm btn-primary">New Tag</div></a>
        </div>
        <div class="card-body">
          <template  v-for="tag in tags">
            <div class="row" v-bind:key="tag.id" style="cursor:pointer;" v-on:click="tagToView=tag">
              <div class="col text-start">
                <!--h5 class="tagClass" :style="tagStyle(tag)">{{tag.title}}</h5-->
                <h5><span class="badge" :style="tagStyle(tag)">{{tag.title}}</span></h5>
                <p>{{tag.description}}</p>
              </div>
            </div>
            <hr v-bind:key="tag.id + 'line'"/>
          </template>
        </div>

      </div>
    </template>
  </div>
</template>

<script>
import TagDetailView from './TagDetailView.vue'
import request from 'request';
export default {
  name: 'TagList',
  components : {
    TagDetailView
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    goBack : function(){
        this.$router.back();
    },
    loadMore : function(){
      this.fetchProjectTags();
    },
    tagStyle : function(tag){
      var styleDict = {
        "background-color" : tag.hexColor,
        "display" : "inline-block"
      }
      return styleDict;
    },
    navigateTo : function(path){
      console.log(path);
      this.$router.push("tags/" + path);
    },
    fetchProjectTags : function(){
      var fetchWith = function(scope) {
        scope.canLoadMore = false;

        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tags",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.tags = jsonObject.tags;
            return
          }
        });
      }
      fetchWith(this);
    },
    updateProjectTag : function(tag){
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tags/" + tag.id,
          json : {
            title : tag.title,
            description: tag.description,
            hexColor: tag.hexColor

          },
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "PUT"
        }, function(error, response){
          if (response.statusCode != 200){
            return
          }
            window.location.href = "/projects/" + scope.$route.params.projectId + "/tags" ;
        });
      }
      fetchWith(this);
    },
    cancel : function(){
        window.location.href = "/projects/" + this.$route.params.projectId + "/tags" ;
    }
  },
  data : function(){
    return {
      tags : [],
      tagToView: null
    }
  },
  created : function(){
    this.fetchProjectTags();
  }
}
</script>
<style>
.tagClass {
  height:28px;
  border-radius: 8px;
  line-height: 8px;
  padding: 10px;
  color: #fff;
}
.ontop-left {
  position:absolute;
  left:10;
}
</style>
