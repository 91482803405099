<template>
      <div class="card defaultMargin">
        <div class="card-header customFont d-flex justify-content-between align-items-center">
          <h5>{{appRoot().currentProject.name}} - Request</h5>
          <div class="dropdown" v-if="!loading">
  <button class="btn btn-sm btn-dark dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    Options
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li class="dropdown-item" @click="editRequest">Edit Request</li>
    <li class="dropdown-item" @click="createTask">Create Task</li>
    <li class="dropdown-item" v-if="!request.closed" @click="closeRequest">Close Request</li>
    <li class="dropdown-item" v-if="request.closed"  @click="openRequest">Open Request</li>
    <li class="dropdown-item text-danger" @click="showDeleteAlert = true">Delete Request</li>
  </ul>
</div>
        </div>
        <div class="card-body text-start" v-if="!loading">
          <h4>{{requestTitle()}}</h4>
          <hr/>
          <p v-if="task != null">{{upperCaseFirst(task.createdBy.firstName)}} {{upperCaseFirst(task.createdBy.lastName)}} created a task for this request. You can view it <a :href="relatedTaskUrl()">here</a>.</p>
          <div class="float-end">
            <span :class="iconFor(request)"></span>
          </div>
          <p style="white-space:prewrap;">{{request.identifier}}</p>
          
          <p style="font-weight:bold;">Description</p>
          <p style="white-space:pre-wrap;">{{request.detailedDescription}}</p>
          <TagsView :tags="request.tags" :parentId="request.id"></TagsView>
          <p style="font-weight:bold;">Comments</p>
          <hr/>
          <RequestDiscussionView :request="request"></RequestDiscussionView>
        </div>
        <div class="card-body" v-if="loading">
          <h5>Please Wait</h5>
          <p>Fetching request details...</p>
          <div>
            <span class="fa fa-spinner fa-spin"></span>
          </div>
        </div>
        <AlertModal
           v-if="showDeleteAlert"
           title="Are you sure?"
           description="Are you sure you want to delete this request?"
           cancelText="Cancel"
           confirmText="Yes, Delete"
           @dismiss="showDeleteAlert = false"
           @confirm="deleteRequest()">
         </AlertModal>
      </div>
</template>

<script>
import TagsView from '../../../../Components/TagsView.vue'
import AlertModal from '../../../../Components/AlertModal.vue'
import RequestDiscussionView from './RequestDiscussionView.vue'
import request from 'request';
export default {
  name: 'RequestView',
  components : {
    TagsView,
    RequestDiscussionView,
    AlertModal
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    upperCaseFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    requestTitle : function(){
      if (this.request.closed != true) {
        return this.request.title;
      }
      return "[Closed] " + this.request.title;
    },
    createTask : function(){
      window.location = "/projects/" + this.$route.params.projectId + "/newtask?requestId=" + this.$route.params.requestId;
    },
    iconFor : function(request){
      var classDict = {
        "fas" : true,
        "fa-2x" : true,
        "float-end" : true
      }
      switch (request.priority) {
        case 5:
        classDict["fa-angle-double-up"] = true;
        classDict["text-danger"] = true;
        break;
        case 4:
        classDict["fa-angle-up"] = true;
        classDict["text-warning"] = true;
        break;
        case 3:
        classDict["fa-angle-right"] = true;
        classDict["text-default"] = true;
        break;
        case 2:
        classDict["fa-angle-down"] = true;
        classDict["text-primary"] = true;
        break;
        case 1:
        classDict["fa-angle-double-down"] = true;
        classDict["text-success"] = true;
        break;
        default:
        break;
      }
      return classDict;
    },
    editRequest : function() {
      window.location.href = "/projects/" + this.$route.params.projectId + "/requests/" + this.$route.params.requestId + "/edit";
    },
    relatedTaskUrl : function(){
      return "/projects/" + this.$route.params.projectId + "/tasks/" + this.task.id;
    },
    openRequest : function() {
      var deleteWith = function(scope) {
        scope.loading = true;
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/requests/" + scope.$route.params.requestId,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          json: {
            closed : false
          },
          method : "PUT"
        }, function(error, response){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          window.location.reload();
        });
      }
      deleteWith(this);
    },
    closeRequest : function() {
      var deleteWith = function(scope) {
        scope.loading = true;
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/requests/" + scope.$route.params.requestId,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          json: {
            closed : true
          },
          method : "PUT"
        }, function(error, response){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          window.location.reload();
        });
      }
      deleteWith(this);
    },
    deleteRequest : function() {
      var deleteWith = function(scope) {
        scope.loading = true;
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/requests/" + scope.$route.params.requestId,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "DELETE"
        }, function(error, response){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          window.location.href = "/projects/" + scope.$route.params.projectId + "/requests";
        });
      }
      deleteWith(this);
    },
    fetchRequestDetails : function(){
      var fetchWith = function(scope) {
        scope.loading = true;
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/requests/" + scope.$route.params.requestId,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.request = jsonObject;
            document.title = "Request - " + jsonObject.title;
            return
          }
        });
      }
      fetchWith(this);
    },
    fetchRelatedTask : function(){
      var fetchWith = function(scope) {
        var params = {
          "requestId" : scope.$route.params.requestId
        };
        //inject non-filter params
        params["limit"] = 1;
        params["offset"] = 0;

        var cleanParams = {}; //Remove NULL values that cause issues... If the filter value is NULL then just don't include it at all in the request.
        for (var key in params) {
          if (params[key] != null){
            cleanParams[key] = params[key];
          }
        }
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tasks",
          qs : cleanParams,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            if (jsonObject.tasks.length > 0){
              scope.task = jsonObject.tasks[0];
            }
          }
        });
      }
      fetchWith(this);
    }

  },
  data : function(){
    return {
      request : null,
      task : null,
      loading : false,
      showDeleteAlert : false
    }
  },
  created : function(){
    document.title = "Request";
    this.fetchRequestDetails();
    this.fetchRelatedTask();
  }
}
</script>
<style>
</style>
