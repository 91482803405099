<template>
  <div>

    <label class="form-label">{{label}}</label>
    <div class="mb-3" v-if="selectedQueue != null">
      <div class="btn text-white" :style="currentQueueStyle(selectedQueue)">{{selectedQueue.name}}</div>
      <button class="btn btn-outline-secondary float-end" type="button" @click="showModal = true" v-if="selectedQueue == null">{{chooseLabel}}</button>
      <button class="btn btn-warning float-end" type="button" @click="showModal = true" v-if="selectedQueue != null">{{changeLabel}}</button>
    </div>
    <div class="mb-3" v-if="selectedQueue == null">
      <label class="form-label">Please choose a queue.</label>
      <button class="btn btn-outline-secondary float-end" type="button" @click="showModal = true">{{chooseLabel}}</button>
    </div>
    <div :id="modalId" :class="modalClass()" :style="modalStyle()" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Select a project queue</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="showModal = false"></button>
          </div>
          <div class="modal-body">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Search...">
            </div>
            <div v-if="loading"><span class="fa fa-spinner fa-spin"></span></div>
            <ul class="list-group" style="max-height:300px;overflow-y:scroll;">
              <li class="list-group-item list-group-item-action" style="cursor:pointer;" v-for="queue in queues" v-bind:key="queue.id" @click="$emit('didSelect', queue);showModal = false;">
                <h5 :style="queueStyle(queue)">{{queue.name}}</h5>
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="showModal = false">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import bootstrap from 'bootstrap';.
import request from 'request';
export default {
  name: 'ProjectQueuePicker',
  props : [
    "label",
    "chooseLabel",
    "changeLabel",
    "selectedQueue"
  ],
  watch : {
    showModal : function(newValue){
      if (newValue) {
        //Modal will appear so we should send the HTTP request to fetch queues list
        this.fetchQueues();
      }
    }
  },
  methods : {
    makeid : function(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    upperCaseFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    currentQueueStyle : function(queue){
      var styleDict = {
        "background-color" : queue.hexColor,
        "cursor" : "default !important"
      }
      return styleDict;
    },
    queueStyle : function(queue){
      var styleDict = {
        "background-color" : queue.hexColor,
        "border-radius" : "4px",
        "color" : "#FFF",
        "padding" : "5px",
        "font-size" : "16px",
        "display" : "initial"
      }
      return styleDict;
    },
    modalClass : function() {
        return {
          "modal" : true,
          "show" : this.showModal
        }
    },
    modalStyle : function() {
      if (this.showModal) {
        return {
          "display" : "block"
        }
      }
      return {
        "display" : "none"
      }
    },
    fetchQueues : function(){
      this.loading = true;
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/queues",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.queues = jsonObject.queues;
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      modalId : this.makeid(5),
      showModal : false,
      loading : false,
      queues : []
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
