<template>
  <div :id="modalId" class="modal show" style="display:block;" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{title}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-if="cancelText != null" @click="$emit('dismiss')"></button>
      </div>
      <div class="modal-body">
        <p>{{description}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" v-if="cancelText != null" @click="$emit('dismiss')">{{cancelText}}</button>
        <button type="button" :class="confirmClass()" v-if="confirmText != null" @click="$emit('confirm');$emit('dismiss');">{{confirmText}}</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
//import bootstrap from 'bootstrap';
export default {
  name: 'AlertModal',
  props : [
    "title",
    "description",
    "cancelText",
    "confirmText",
    "confirmButtonClass"
  ],
  methods : {
   makeid : function(length) {
       var result           = '';
       var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
       var charactersLength = characters.length;
       for ( var i = 0; i < length; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
       }
       return result;
    },
    confirmClass : function(){
      if (this.confirmButtonClass == null) {
        return {
          "btn" : true,
          "btn-primary" : true
        }
      }
      var customClass = {
        "btn" : true
      }
      customClass[this.confirmButtonClass] = true;
      return customClass;
    }
  },
  data : function(){
    return {
      modalId : this.makeid(5)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
