<template>
  <div>
      <div class="row">
        <div class="col-md">
          <div class="card defaultMargin">
            <div class="card-header customFont d-flex justify-content-between align-items-center">
              <h5>Groups</h5>
      <button class="btn btn-sm btn-dark" type="button" @click="newGroup()">
        <i class="fa fa-plus"></i>
      </button>
            </div>
            <div class="card-body">
              <div class="list-group">
                <button v-for="group in groups" v-bind:key="group.id" :class="classFor(group)" style="cursor:pointer;" v-on:click="navigateTo(group.id)">{{group.name}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import request from 'request';
export default {
  name: 'ProjectGroupsView',
  components : {
  },
  methods : {
    newGroup : function() {
      window.location = "/projects/" + this.$route.params.projectId + "/newgroup";
    },
    classFor : function(group) {
      return {
        "list-group-item" : true,
        "list-group-item-action" : true,
        "active" : this.$route.params.groupId == group.id
      }
    },
    fetchProjectGroups : function(){
      var fetchWith = function(scope) {
        scope.loading = true;
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/groups",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.groups = jsonObject.groups;
            return
          }
        });
      }
      fetchWith(this);
    },
    navigateTo : function(path){
      window.location.href = "/projects/" + this.$route.params.projectId + "/groups/" + path;
    }
  },
  created : function(){
    this.fetchProjectGroups();
  },
  data : function() {
    return {
      groups : [],
      loading : false
    }
  }
}
</script>
