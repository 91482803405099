<template>
<div class="userOrb">
  <h5 v-if="!showImage">
    {{defaultValue}}
  </h5>
  <!--<img v-if="showImage" width='100%' height='100%' src='https://macchiatoapi.herokuapp.com/users/0CBC0C26-29BD-4FDB-885C-34D34F5D0C7B/profile-picture'/>-->
  <img v-if="showImage" width='100%' height='100%' :src='s3Url' />
</div>
</template>

<script>
import request from 'request';
export default {
  name: 'PictureView',
  //props : ['defaultValue','userId','imgURL'],
  props: ['defaultValue', 'imgURL'],
  methods: {
    fetchProfilePicture: function() {

      var self = this

      //if (self.imgURL != null){
      self.s3Url = window.apiUrl + self.imgURL
      //} else {
      //self.s3Url = window.apiUrl + "/users/" + self.userId + "/profile-picture"
      //}
      function headS3(presignedURL) {
        request({
          url: presignedURL,
          method: "HEAD"
        }, function(error, response) {
          console.log("s3 status code " + response.statusCode);
          if (response.statusCode != 200) {
            console.log("macchiato head request failed.")
            return
          }
          self.showImage = true;
        })
      }

      function headMacchiato() {

        request({
          //url: window.apiUrl + "/users/" + self.userId + "/profile-picture",
          url: self.s3Url,
          method: "HEAD"
        }, function(error, response) {
          if (response.statusCode != 200 || response.headers["x-presigned-url"] == null) {
            console.log("s3 head failed")
            return
          }
          //self.s3Url = window.apiUrl + "/users/" + self.userId + "/profile-picture";
          headS3(response.headers["x-presigned-url"]);
        })
      }

      headMacchiato();

    }
  },
  data: function() {
    return {
      showImage: false,
      //userId : "0CBC0C26-29BD-4FDB-885C-34D34F5D0C7B",
      s3Url: ""
    }
  },
  created: function() {
    this.fetchProfilePicture();
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.userOrb {
  font-size: 10px;
  background-color: rgb(18, 153, 226);
  border-radius: 20px;
  width: 40px;
  height: 40px;
  color: white;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  overflow: hidden;
}

.userOrb h5 {
  margin-top: 8px;
}
</style>
