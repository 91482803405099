<template>
    <div class="card defaultMargin">
      <div class="card-header customFont d-flex justify-content-between align-items-center">
        <h5>{{appRoot().currentProject.name}} - Edit Tag - {{tagToEdit.title}}</h5>
      </div>
      <div class="card-body text-start">
        <div class="mb-3">
          <label class="form-label">Tag Label</label>
          <input type="text" class="form-control" v-model="tagToEdit.title">
        </div>
        <div class="mb-3">
          <label class="form-label">Description</label>
          <textarea type="text" class="form-control" v-model="tagToEdit.description"></textarea>
        </div>
        <div class="mb-3">
          <label class="form-label">Select Tag Colour</label>
          <input type="color" class="form-control form-control-color" v-model="tagToEdit.hexColor">
        </div>
        <div class="mb-3">
          <div class="btn btn-warning float-start" @click="$emit('dismiss')">Cancel</div>
          <div class="btn btn-primary float-end" @click="updateProjectTag(tagToEdit)">Save</div>
        </div>
      </div>
    </div>
</template>

<script>
import request from 'request';
export default {
  name: 'EditTag',
  props : ["tagToEdit"],
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    updateProjectTag : function(tag){
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tags/" + tag.id,
          json : {
            title : tag.title,
            description: tag.description,
            hexColor: tag.hexColor

          },
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "PUT"
        }, function(error, response){
          if (response.statusCode != 200){
            return
          }
            scope.$emit('dismiss');
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
    }
  },
  created : function(){
  }
}
</script>
<style>
.tagClass {
  height:28px;
  border-radius: 8px;
  line-height: 8px;
  padding: 10px;
  color: #fff;
}
.ontop-left {
  position:absolute;
  left:10;
}
</style>
