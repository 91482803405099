<template>
  <div>

    <label class="form-label">{{label}}</label>
    <div class="input-group mb-3">
      <button class="btn btn-warning" type="button" @click="$emit('didSelect', null);" v-if="selectedMember != null"><div><span class="fa fa-times"></span></div></button>
      <input type="text" class="form-control" :value="selectedMemberName()" disabled>
      <button class="btn btn-outline-secondary" type="button" @click="showModal = true" v-if="selectedMember == null">{{chooseLabel}}</button>
      <button class="btn btn-warning" type="button" @click="showModal = true" v-if="selectedMember != null">{{changeLabel}}</button>
    </div>

    <div :id="modalId" :class="modalClass()" :style="modalStyle()" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Select a project member</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="showModal = false"></button>
          </div>
          <div class="modal-body">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Search..." v-model="search">
            </div>
            <div v-if="loading"><span class="fa fa-spinner fa-spin"></span></div>
            <ul class="list-group" style="max-height:300px;overflow-y:scroll;">
              <!--<li class="list-group-item list-group-item-action" style="cursor:pointer;" v-for="member in members" v-bind:key="member.id" @click="$emit('didSelect', translateMember(member));showModal = false;">
                {{upperCaseFirst(member.firstName)}} {{upperCaseFirst(member.lastName)}}
              </li>-->
              <li class="list-group-item list-group-item-action" style="cursor:pointer;" v-for="member in fileredMembers" v-bind:key="member.id" @click="$emit('didSelect', translateMember(member));showModal = false;">
                {{upperCaseFirst(member.firstName)}} {{upperCaseFirst(member.lastName)}}
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="showModal = false">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import bootstrap from 'bootstrap';.
import request from 'request';
export default {
  name: 'ProjectMemberPicker',
  props : [
    "label",
    "chooseLabel",
    "changeLabel",
    "selectedMember"
  ],
  watch : {
    showModal : function(newValue){
      if (newValue) {
        //Modal will appear so we should send the HTTP request to fetch members list
        this.fetchMembers();
      }
    }
  },
  methods : {
    makeid : function(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    upperCaseFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    modalClass : function() {
        return {
          "modal" : true,
          "show" : this.showModal
        }
    },
    translateMember : function(member) {
      return {
        "id" : member.userId,
        "firstName" : member.firstName,
        "lastName" : member.lastName
      }
    },
    modalStyle : function() {
      if (this.showModal) {
        return {
          "display" : "block"
        }
      }
      return {
        "display" : "none"
      }
    },
    selectedMemberName : function(){
      if (this.selectedMember == null) { return "" }
      return this.upperCaseFirst(this.selectedMember.firstName) + " " + this.upperCaseFirst(this.selectedMember.lastName);
    },
    fetchMembers : function(){
      this.loading = true;
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/members",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.members = jsonObject.members;
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      modalId : this.makeid(5),
      showModal : false,
      loading : false,
      members : [],
      search : ''
    }
  },
  computed: {
    fileredMembers() {
      return this.members.filter(member => {
        return (member.firstName.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || member.lastName.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
      })
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
