<template>
  <div class="card defaultMargin">
    <div class="card-header customFont d-flex justify-content-between align-items-center">
      <h5>{{appRoot().currentProject.name}} - Edit Request</h5>
    </div>
    <div class="card-body" v-if="loading">
      <h5>Please Wait</h5>
      <p>Fetching request details...</p>
      <div>
        <span class="fa fa-spinner fa-spin"></span>
      </div>
    </div>
    <div class="card-body text-start" v-if="!loading">
      <div class="mb-3">
        <label class="form-label">Request Title</label>
        <input type="text" class="form-control" v-model="title">
      </div>
      <div class="mb-3">
        <label class="form-label">Request Description</label>
        <textarea type="text" class="form-control" v-model="description"></textarea>
      </div>
      <div class="row">
        <div class="mb-3">
          <label class="form-label">Request Priority</label>
          <select class="form-select form-control" v-model="priority">
            <option value="1">Lowest</option>
            <option value="2">Low</option>
            <option value="3" selected>Medium</option>
            <option value="4">High</option>
            <option value="5">Highest</option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="mb-3">
          <label class="form-label">Tags</label>
          <div class="form-text text-muted">Click / Tap to add or remove tags.</div>
          <TagBuilder :selectedTags="selectedTags" style="margin-bottom:50px"></TagBuilder>
        </div>
      </div>
      <div class="mb-3">
          <div class="btn btn-warning float-start" @click="cancel()">Cancel</div>
        <div class="btn btn-primary float-end" @click="updateProjectRequest()">Save</div>
      </div>
    </div>
  </div>
</template>

<script>
import TagBuilder from '../../../../Components/TagBuilder.vue'
import request from 'request';
export default {
  name: 'EditRequest',
  components : {
    TagBuilder
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    iconFor : function(request){
      var classDict = {
        "fas" : true,
        "fa-2x" : true,
        "float-end" : true
      }
      switch (request.priority) {
        case 5:
        classDict["fa-angle-double-up"] = true;
        classDict["text-danger"] = true;
        break;
        case 4:
        classDict["fa-angle-up"] = true;
        classDict["text-warning"] = true;
        break;
        case 3:
        classDict["fa-angle-right"] = true;
        classDict["text-default"] = true;
        break;
        case 2:
        classDict["fa-angle-down"] = true;
        classDict["text-primary"] = true;
        break;
        case 1:
        classDict["fa-angle-double-down"] = true;
        classDict["text-success"] = true;
        break;
        default:
        break;
      }
      return classDict;
    },
    updateProjectRequest : function(){
      var fetchWith = function(scope) {

        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/requests/" + scope.$route.params.requestId,
          json : {
            title : scope.title,
            detailedDescription: scope.description,
            priority : parseInt(scope.priority),
            closed : scope.closed,
            tags : scope.selectedTags.map(function(tag){
              return tag.id
            })
          },
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "PUT"
        }, function(error, response){
          if (response.statusCode != 200){
            return
          }
            window.location.href = "/projects/" + scope.$route.params.projectId + "/requests/" + scope.$route.params.requestId ;
        });
      }
      fetchWith(this);
    },
    cancel : function(){
        window.location.href = "/projects/" + this.$route.params.projectId + "/requests/" + this.$route.params.requestId ;
    },
    fetchRequestDetails : function(){
      var fetchWith = function(scope) {
        scope.loading = true;
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/requests/" + scope.$route.params.requestId,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.title = jsonObject.title;
            scope.description = jsonObject.detailedDescription;
            scope.selectedTags = jsonObject.tags;
            scope.priority = jsonObject.priority;
            scope.closed = jsonObject.closed;
            return
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      title : "",
      priority : 3,
      description : "",
      selectedTags : [],
      closed : false,
      loading : false
    }
  },
  created : function(){
    this.fetchRequestDetails();
  }
}
</script>
