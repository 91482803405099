<template>
  <div :id="modalId" class="modal show" style="display:block;" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Quick Tips</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$emit('dismiss')"></button>
        </div>
        <div class="modal-body text-start">
          <p>We thought you might like a few quick tips to help you get started with your new project!</p>
          <div class="row">
            <h5>1. Project Notifications</h5>
            <p>Make sure you choose what notifications you would like to receive for this project.</p>
            <div class="btn-group">
              <button class="btn btn-outline-secondary" @click="navigateTo('preferences/notifications')">Notification Preferences</button>
          </div>
          </div>
          <br/>
          <div class="row">
            <h5>2. Project Usage</h5>
            <p>Check your project limits and consider subscribing for a higher quota.</p>
            <div class="btn-group">
            <button class="btn btn-outline-secondary"  @click="navigateTo('preferences/usage')">Usage and Limits</button>
            </div>
          </div>
          <br/>
          <div class="row">
            <h5>3. Project Members</h5>
            <p>Invite people to join this project and start collaborating!</p>
            <div class="input-group mb-1">
            <input type="text" class="form-control" :value="projectUrl()">
        <button class="btn btn-outline-secondary" type="button" @click="copyTextToClipboard(projectUrl())">{{copyText}}</button>
        </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="$emit('dismiss')">Dismiss</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import bootstrap from 'bootstrap';
export default {
  name: 'TipsModal',
  methods : {
    makeid : function(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    projectUrl : function(){
      return "https://macchiato.kappuccino.uk/projects/" + this.$route.params.projectId;
    },
   fallbackCopyTextToClipboard : function(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
},
navigateTo : function(path){
  window.location.href = "/projects/" + this.$route.params.projectId + "/" + path;
},
 copyTextToClipboard: function(text) {
   this.copyText = "Copied!";
  if (!navigator.clipboard) {
    this.fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}
  },
  data : function(){
    return {
      modalId : this.makeid(5),
      copyText : "Copy"
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
