<template>
  <div class="card defaultMargin">
    <div class="card-header customFont d-flex justify-content-between align-items-center">
      <h5>{{appRoot().currentProject.name}} - Task</h5>
      <div class="dropdown" v-if="!loading">
        <button class="btn btn-sm btn-dark dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
          Options
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li class="dropdown-item" @click="editTask">Edit Task</li>
          <li class="dropdown-item" v-if="!task.closed" @click="closeTask">Close Task</li>
          <li class="dropdown-item" v-if="task.closed"  @click="openTask">Open Task</li>
          <li class="dropdown-item text-danger" @click="showDeleteAlert = true">Delete Task</li>
        </ul>
      </div>
    </div>
    <div class="card-body text-start" v-if="!loading">
      <div>
        <!--<h4 class="float-start">{{taskTitle()}}</h4>-->
        <h4 class="float-start">{{taskTitle()}}</h4>
        <h5 class="float-end" :style="queueStyle()" v-if="task.queue != null">{{task.queue.name}}</h5>
        <br/>
      </div>
      <hr/>
      <p v-if="task.requestedBy != null">This was originally requested by {{upperCaseFirst(task.requestedBy.firstName)}} {{upperCaseFirst(task.requestedBy.lastName)}}. View the original request <a :href="originalRequestUrl()">here</a>.</p>
      <div class="float-end">
        <span :class="iconFor(task)"></span>
      </div>
      
      <p style="white-space:pre-wrap;">{{task.identifier}}</p>

      <p style="font-weight:bold;">Assigned To</p>
      <p style="white-space:pre-wrap;">{{upperCaseFirst(task.assignedTo.firstName)}} {{upperCaseFirst(task.assignedTo.lastName)}}</p>
      
      <p style="font-weight:bold;">Description</p>
      <p style="white-space:pre-wrap;">{{task.detailedDescription}}</p>
      <TagsView :tags="task.tags" :parentId="task.id"></TagsView>
      <p style="font-weight:bold;">Timeline</p>
      <hr/>
      <!--<TaskDiscussionView :task="task"></TaskDiscussionView>-->
      <TaskTimelineView :task="task"></TaskTimelineView>
    </div>
    <div class="card-body" v-if="loading">
      <h5>Please Wait</h5>
      <p>Fetching task details...</p>
      <div>
        <span class="fa fa-spinner fa-spin"></span>
      </div>
    </div>
    <AlertModal
      v-if="showDeleteAlert"
      title="Are you sure?"
      description="Are you sure you want to delete this task?"
      cancelText="Cancel"
      confirmText="Yes, Delete"
      @dismiss="showDeleteAlert = false"
      @confirm="deleteTask()">
    </AlertModal>
  </div>
</template>

<script>
import TagsView from '../../../../Components/TagsView.vue'
import AlertModal from '../../../../Components/AlertModal.vue'
//import TaskDiscussionView from './TaskDiscussionView.vue'
import TaskTimelineView from './TaskTimelineView.vue'
import request from 'request';
export default {
  name: 'Task',
  components : {
    TagsView,
    //TaskDiscussionView,
    TaskTimelineView,
    AlertModal
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    upperCaseFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    queueStyle : function(){
      var styleDict = {
        "cursor" : "pointer",
        "background-color" : this.task.queue.hexColor,
        "border-radius" : "4px",
        "color" : "#FFF",
        "padding" : "5px",
        "font-size" : "16px"
      }
      return styleDict;
    },
    taskTitle : function(){
      if (this.task.closed != true) {
        return this.task.title;
      }
      return "[Closed] " + this.task.title;
    },
    iconFor : function(request){
      var classDict = {
        "fas" : true,
        "fa-2x" : true,
        "float-end" : true
      }
      switch (request.priority) {
        case 5:
        classDict["fa-angle-double-up"] = true;
        classDict["text-danger"] = true;
        break;
        case 4:
        classDict["fa-angle-up"] = true;
        classDict["text-warning"] = true;
        break;
        case 3:
        classDict["fa-angle-right"] = true;
        classDict["text-default"] = true;
        break;
        case 2:
        classDict["fa-angle-down"] = true;
        classDict["text-primary"] = true;
        break;
        case 1:
        classDict["fa-angle-double-down"] = true;
        classDict["text-success"] = true;
        break;
        default:
        break;
      }
      return classDict;
    },
    editTask : function() {
      window.location.href = "/projects/" + this.$route.params.projectId + "/tasks/" + this.$route.params.taskId + "/edit";
    },
    openTask : function() {
      var deleteWith = function(scope) {
        scope.loading = true;
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tasks/" + scope.$route.params.taskId,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          json: {
            closed : false
          },
          method : "PUT"
        }, function(error, response){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          window.location.reload();
        });
      }
      deleteWith(this);
    },
    closeTask : function() {
      var deleteWith = function(scope) {
        scope.loading = true;
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tasks/" + scope.$route.params.taskId,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          json: {
            closed : true
          },
          method : "PUT"
        }, function(error, response){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          window.location.reload();
        });
      }
      deleteWith(this);
    },
    deleteTask : function() {
      var deleteWith = function(scope) {
        scope.loading = true;
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tasks/" + scope.$route.params.taskId,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "DELETE"
        }, function(error, response){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          window.location.href = "/projects/" + scope.$route.params.projectId + "/tasks";
        });
      }
      deleteWith(this);
    },
    originalRequestUrl: function(){
      return "/projects/" + this.$route.params.projectId + "/requests/" + this.task.requestId;
    },
    fetchTaskDetails : function(){
      var fetchWith = function(scope) {
        scope.loading = true;
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tasks/" + scope.$route.params.taskId,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.task = jsonObject;
            document.title = "Task - " + jsonObject.title;
            return
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      task : null,
      loading : false,
      showDeleteAlert : false
    }
  },
  created : function(){
    document.title = "Task";
    this.fetchTaskDetails();
  }
}
</script>
<style>
</style>
