<template>
  <div class="card defaultMargin">
    <div class="card-header customFont d-flex justify-content-between align-items-center">
      <h5>{{appRoot().currentProject.name}} - New Task</h5>
    </div>
    <div class="card-body text-start">
      <div class="mb-3">
        <label class="form-label">Task Title</label>
        <input type="text" class="form-control" v-model="title">
      </div>
      <ProjectQueuePicker label="Queue" chooseLabel="Choose Queue" changeLabel="Change" :selectedQueue="queue" @didSelect="changeQueue" />
      <ProjectMemberPicker label="Assigned To" chooseLabel="Assign this task" changeLabel="Reassign" :selectedMember="assignedTo" @didSelect="assignMember" />
      <div class="mb-3">
        <label class="form-label">Task Priority</label>
        <select class="form-select form-control" v-model="priority">
          <option value="1">Lowest</option>
          <option value="2">Low</option>
          <option value="3" selected>Medium</option>
          <option value="4">High</option>
          <option value="5">Highest</option>
        </select>
      </div>
      <div class="mb-3">
        <label class="form-label">Task Description</label>
        <textarea type="text" class="form-control" v-model="description"></textarea>
      </div>
      <div class="row">
        <label class="form-label">Tags</label>
        <div class="form-text text-muted">Click / Tap to add or remove tags.</div>
        <TagBuilder :selectedTags="selectedTags"></TagBuilder>
      </div>
      <br/>
      <div class="row">
        <label class="form-label float-start">Time Estimate</label><br/>
      <div class="mb-3" v-if="!useTimeEstimate">
          <label class="form-label">Would you like to apply a time estimate?</label>
          <button class="btn btn-outline-secondary float-end" @click="useTimeEstimate = true;">Estimate Time</button>
      </div>
      <div class="mb-3" v-if="useTimeEstimate">
        <div class="input-group">
          <input type="number" class="form-control text-end" v-model="estimatedDays">
          <span class="input-group-text">days</span>
          <input type="number" class="form-control text-end" v-model="estimatedHours">
          <span class="input-group-text">hours</span>
          <input type="number" class="form-control text-end" v-model="estimatedMinutes">
          <span class="input-group-text">minutes</span>
          <button class="btn btn-outline-danger float-end" @click="useTimeEstimate = false;">Remove Estimate</button>
        </div>
      </div>
      </div>
      <br/>
      <div class="row">
        <label class="form-label float-start">Deadline</label><br/>
      <div class="mb-3" v-if="!useDeadline">
          <label class="form-label">Would you like to apply a deadline?</label>
          <button class="btn btn-outline-secondary float-end" @click="useDeadline = true;">Apply a deadline</button>
      </div>
      <div class="mb-3" v-if="useDeadline">
        <div class="input-group">
          <input type="datetime-local" class="form-control" v-model="deadline">
          <button class="btn btn-outline-danger float-end" @click="useDeadline = false;">Remove Deadline</button>
        </div>
      </div>
      </div>
      <br/><br/>
      <div class="mb-3">
        <div class="btn btn-warning float-start" @click="cancel()">Cancel</div>
        <div class="btn btn-primary float-end" @click="createProjectTask()">Save</div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectMemberPicker from '../../../../Components/ProjectMemberPicker.vue'
import ProjectQueuePicker from '../../../../Components/ProjectQueuePicker.vue'
import TagBuilder from '../../../../Components/TagBuilder.vue'
import request from 'request';
export default {
  name: 'NewTask',
  components : {
    TagBuilder,
    ProjectMemberPicker,
    ProjectQueuePicker
  },
  watch : {
    estimatedMinutes : function(newValue) {
      if (newValue > 60) {
        this.estimatedHours += 1;
        this.estimatedMinutes = newValue - 60;
      }

      this.timeEstimateInSeconds = 0;
      this.timeEstimateInSeconds += this.estimatedMinutes * 60;
      this.timeEstimateInSeconds += this.estimatedHours * 60 * 60;
      this.timeEstimateInSeconds += this.estimatedDays * 24 * 60 * 60;
    },
    estimatedHours : function(newValue) {
      if (newValue > 24) {
        this.estimatedDays += 1;
        this.estimatedHours = newValue - 24;
      }

      this.timeEstimateInSeconds = 0;
      this.timeEstimateInSeconds += this.estimatedMinutes * 60;
      this.timeEstimateInSeconds += this.estimatedHours * 60 * 60;
      this.timeEstimateInSeconds += this.estimatedDays * 24 * 60 * 60;
    },
    estimatedDays : function() {
      this.timeEstimateInSeconds = 0;
      this.timeEstimateInSeconds += this.estimatedMinutes * 60;
      this.timeEstimateInSeconds += this.estimatedHours * 60 * 60;
      this.timeEstimateInSeconds += this.estimatedDays * 24 * 60 * 60;
    }
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    assignMember : function(member){
      this.assignedTo = member
    },
    changeQueue : function(queue){
      this.queue = queue
    },
    iconFor : function(request){
      var classDict = {
        "fas" : true,
        "fa-2x" : true,
        "float-end" : true
      }
      switch (request.priority) {
        case 5:
        classDict["fa-angle-double-up"] = true;
        classDict["text-danger"] = true;
        break;
        case 4:
        classDict["fa-angle-up"] = true;
        classDict["text-warning"] = true;
        break;
        case 3:
        classDict["fa-angle-right"] = true;
        classDict["text-default"] = true;
        break;
        case 2:
        classDict["fa-angle-down"] = true;
        classDict["text-primary"] = true;
        break;
        case 1:
        classDict["fa-angle-double-down"] = true;
        classDict["text-success"] = true;
        break;
        default:
        break;
      }
      return classDict;
    },
    cancel : function(){
        window.location.href = "/projects/" + this.$route.params.projectId + "/tasks";
    },
    createProjectTask : function(){
      var fetchWith = function(scope) {
        var jsonBody  = {
          title : scope.title,
          detailedDescription: scope.description,
          priority : parseInt(scope.priority),
          queueId : (scope.queue == null ? "" : scope.queue.id),
          closed : false,
          requestId : scope.requestId,
          deadline : (scope.useDeadline ? (new Date(scope.deadline).getTime()) / 1000 : null),
          timeEstimateInSeconds : (scope.timeEstimateInSeconds ? scope.timeEstimateInSeconds : 0),
          tags : scope.selectedTags.map(function(tag){
            return tag.id
          })
        };

        if (scope.assignedTo != null){
          jsonBody["assignedTo"] = scope.assignedTo.id;
        }


        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tasks",
          json : jsonBody,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "POST"
        }, function(error, response, json){
          if (response.statusCode != 201){
            return
          }
          if (error != null){
            console.log("whatttt");
            return
          }
          if (json != null){
            //Navigate to that task using the new identifier created...
            window.location.href = "tasks/" + json.id;
            return
          }
        });
      }
      fetchWith(this);
    },
    fetchRequestDetails : function(requestId){
      var fetchWith = function(scope) {
        scope.loadingRequest = true;
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/requests/" + requestId,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          scope.loadingRequest = false;
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.requestId = jsonObject.id;
            scope.title = jsonObject.title;
            scope.priority = jsonObject.priority;
            scope.description = jsonObject.detailedDescription;
            scope.selectedTags = jsonObject.tags;
            return
          }
        });
      }
      fetchWith(this);
    },
  },
  data : function(){
    return {
      title : "",
      priority : 3,
      description : "",
      selectedTags : [],
      assignedTo : null,
      queue : null,
      requestId : null,
      loadingRequest : false,
      useDeadline : false,
      deadline : null,
      useTimeEstimate : null,
      timeEstimateInSeconds : 0,
      estimatedDays : 0,
      estimatedHours : 0,
      estimatedMinutes : 0
    }
  },
  created : function(){
    if (this.$route.query.requestId != null){
      this.fetchRequestDetails(this.$route.query.requestId);
    }
  }
}
</script>
