<template>
  <div>
    <template v-if="loading == true">
      <div class="row">
        <div class="col-md"></div>
        <div class="col-md">
          <div class="card defaultMargin">
            <div class="card-header">
              Please Wait
            </div>
            <div class="card-body">
              <h5 class="card-title">Fetching Queue</h5>
              <p class="card-text">Please wait, we're just asking for more information...</p>
            </div>
          </div>
        </div>
        <div class="col-md"></div>
      </div>
    </template>
    <template v-if="loading != true">
    <EditQueue :queueToEdit="queueToEdit" v-if="queueToEdit != null" @dismiss="queueToEdit = null;fetchProjectQueue();"></EditQueue>
    <template v-if="queueToEdit == null">
      <div class="card defaultMargin">
        <div class="card-header customFont d-flex justify-content-between align-items-center">
          <h5>{{appRoot().currentProject.name}} - {{queue.name}}</h5>
          <div class="dropdown" v-if="!loading">
            <button class="btn btn-sm btn-dark dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              Options
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li class="dropdown-item" @click="queueToEdit = queue">Edit Queue</li>
              <li class="dropdown-item text-danger" @click="showDeleteAlert = true; queueToDelete = queue;">Delete Queue</li>
            </ul>
          </div>
          <!--<div class="col text-end">
            <div class="btn-group">
              <div class="btn btn-sm btn-primary" @click="queueToEdit = queue">Edit Queue</div>
              <div class="btn btn-sm btn-danger" @click="showDeleteAlert = true; queueToDelete = queue;">Delete Queue</div>
            </div>
          </div>-->
        </div>
        <div class="card-body">
          <template v-if="tasks.length == 0">
            <div class="row">
              <div class="col text-start">
                <p>This queue currently has no tasks.</p>
              </div>
            </div>
          </template>
          <template  v-for="task in tasks">
            <div class="row" v-bind:key="task.id" style="cursor:pointer;" v-on:click="navigateTo(task.id)">
              <div class="col-sm-1 text-end">
                <div class="col-lg text-end" v-if="task.assignedTo != null">
                  <!--<h5 class="userOrb">
                    {{task.assignedTo.firstName[0].toUpperCase()}}{{task.assignedTo.lastName[0].toUpperCase()}}
                  </h5>-->
                  <!--<PictureView class="float-start" :defaultValue='task.assignedTo.firstName[0].toUpperCase() + task.assignedTo.lastName[0].toUpperCase()' :userId='task.assignedTo.id'></PictureView>-->
                  <PictureView class="float-start" :defaultValue='task.assignedTo.firstName[0].toUpperCase() + task.assignedTo.lastName[0].toUpperCase()' :imgURL='"/users/" + task.assignedTo.id + "/profile-picture"'></PictureView>
                </div>
                <div class="col-lg">
                  <span :class="iconFor(task)"></span>
                </div>
              </div>
              <div class="col text-start">
                <h5>{{task.title}}</h5>
                <p>{{task.detailedDescription}}</p>
                <TagsView :tags="task.tags" :parentId="task.id"></TagsView>
                <p class="text-muted">{{dateStringForEpoch(task.createdAt)}}</p>
              </div>
            </div>
            <hr v-bind:key="task.id + 'line'"/>
          </template>
        </div>
        <AlertModal
           v-if="showDeleteAlert"
           title="Are you sure?"
           description="Are you sure you want to delete this queue?"
           cancelText="Cancel"
           confirmText="Yes, Delete"
           @dismiss="showDeleteAlert = false"
           @confirm="deleteQueue(queueToDelete)">
         </AlertModal>
      </div>
    </template>
  </template>
  </div>
</template>

<script>
import TagsView from '../../../../Components/TagsView.vue'
import AlertModal from '../../../../Components/AlertModal.vue'
import PictureView from '../../../../Components/PictureView.vue'
import request from 'request';
import EditQueue from './EditQueue.vue'
export default {
  name: 'QueueTaskList',
  props : [],
  components : {
    TagsView,
    AlertModal,
    PictureView,
    EditQueue
  },
  watch : {
    filters : {
      handler : function() {
        this.skip  = 0;
        this.tasks = [];
        this.fetchProjectTasks();
      },
      deep: true
    }
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    dateStringForEpoch : function(epoch) {
      let date = new Date(epoch * 1000);
      let dateString = date.toLocaleDateString();
      let timeString = date.toLocaleTimeString();
      return dateString + " at " + timeString;
    },
    navigateTo : function(path){
      console.log(path);
      // if (this.$route.name == "projectMenu") {
      //   this.$router.push(this.$route.params.projectId + '/' + path);
      // }else {
        this.$router.push("/projects/" + this.$route.params.projectId + "/tasks/" + path)
      //}
    },
    goBack : function(){
        this.$router.back();
    },
    iconFor : function(task){
      var classDict = {
        "fas" : true,
        "fa-2x" : true,
        "float-end" : true
      }
      switch (task.priority) {
        case 5:
        classDict["fa-angle-double-up"] = true;
        classDict["text-danger"] = true;
        break;
        case 4:
        classDict["fa-angle-up"] = true;
        classDict["text-warning"] = true;
        break;
        case 3:
        classDict["fa-angle-right"] = true;
        classDict["text-default"] = true;
        break;
        case 2:
        classDict["fa-angle-down"] = true;
        classDict["text-primary"] = true;
        break;
        case 1:
        classDict["fa-angle-double-down"] = true;
        classDict["text-success"] = true;
        break;
        default:
        break;
      }
      return classDict;
    },
    fetchProjectTasks : function(){
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tasks",
          qs : {
            queueId : scope.queue.id,
            sortBy : "createdAt",
            sortOrder : "DESC",
          },
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.tasks = jsonObject.tasks;
            return
          }
        });
      }
      fetchWith(this);
      console.log("number of tasks for queue is " + this.tasks.length)
    },
    fetchProjectQueue : function(){
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/queues",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.queue = jsonObject.queues.filter(function(queue){
              return queue.id == scope.$route.params.queueId;
            })[0];
            if (scope.queue == null) {
              return;
            }
            scope.loading = false;
            scope.fetchProjectTasks();

            return
          }
        });
      }
      fetchWith(this);
    },
    deleteQueue : function(queue) {
      var deleteWith = function(scope) {
        scope.loading = true;
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/queues/" + queue.id,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "DELETE"
        }, function(error, response){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          window.location.href = "/projects/" + scope.$route.params.projectId + "/queues";
        });
      }
      deleteWith(this);
    }

  },
  data : function(){
    return {
      tasks : [],
      queueToEdit : null,
      queueToDelete : null,
      showDeleteAlert : false,
      queue : null,
      loading : true
    }
  },
  created : function(){
    this.fetchProjectQueue();
  }
}
</script>
<style scoped>
/* .userOrb {
  font-size: 16px;
  background-color:rgb(18,153,226);
  border-radius:20px;
  width:40px;
  height:40px;
  color: white;
  text-align: center;
  vertical-align: middle;
  line-height:40px;
} */

.ontop-left {
  position:absolute;
  left:10;
}
</style>
