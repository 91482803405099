<template>
  <div>
    <h1 class="customFont">Login</h1>
    <div class="container text-start">
      <div class="mb-3">
        <label class="form-label">Email address</label>
        <input type="email" class="form-control" v-model="email">
        <div class="form-text">We'll never share your email with anyone else.</div>
      </div>
      <div class="mb-3">
        <label class="form-label">Password</label>
        <input type="password" class="form-control" v-model="password">
      </div>
      <h5 class="text-danger">{{error}}</h5>
      <button class="btn btn-default text-primary float-start" v-if="!loading" @click="register()">Create Account</button>
      <button class="btn btn-primary float-end" v-if="!loading" @click="attemptLogin()">Login</button>
      <div class="float-end" v-if="loading"><span class="fa fa-spinner fa-spin"></span></div>
      <br/><br/>
    </div>
  </div>
</template>

<script>
import request from 'request';
export default {
  name: 'LoginEmail',
  methods : {
    attemptLogin : function(){
      var self = this;
      self.loading = true;
      self.error = "";
      request({
        url: window.apiUrl + "/login",
        json : {
          "email" : self.email,
          "password" : self.password
        },
        method : "POST"
      }, function(error, response, json){
        self.loading = false;
        if (response.statusCode != 200){
          self.error = "Check your credentials and try again."
          return
        }
        if (error != null){
          self.error = error;
          return
        }
        if (json != null){
          //Navigate to that task using the new identifier created...
          window.location.href = "/?token=" + json.jwt;
          return
        }
        self.error = "Check your credentials and try again."
      });
    },
    register : function() {
      window.location = "/login/register";
    }
  },
  data : function(){
    return  {
      loading : false,
      error : "",
      email : "",
      password : ""
    }
  }
}
</script>
