<template>
      <div class="card defaultMargin">
        <div class="card-header customFont d-flex justify-content-between align-items-center">
          <h5>{{appRoot().currentProject.name}} - Members</h5>
          <div class="btn-group">
  <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    <span class="fa fa-link"></span>
  </button>
  <ul class="dropdown-menu" style="width:400px;padding:10px;">
    <p>Use this link to share your project</p>
    <div class="input-group mb-1">
    <input type="text" class="form-control" :value="projectUrl()">
<button class="btn btn-outline-secondary" type="button" @click="copyTextToClipboard(projectUrl())">Copy</button>
</div>

  </ul>
</div>
        </div>
        <div class="card-body" style="margin-bottom:10px;">
          <div class="row" style="margin-bottom:10px;">
            <div class="col text-start">
                <h4 class="float-start customFont">Access Requests</h4>
            </div>
          </div>
          <div class="row" v-if="accessRequests.length == 0">
            <div class="col text-start">
              <h5>This project has no access requests. Check back later.</h5>
            </div>
            <hr/>
          </div>
          <template  v-for="accessRequest in accessRequests">
            <div class="row" v-bind:key="accessRequest.id">
              <div class="col-10 text-start">
                  <!--<h5 class="userOrb float-start">
                    {{accessRequest.requestedBy.firstName[0].toUpperCase()}}{{accessRequest.requestedBy.lastName[0].toUpperCase()}}
                  </h5>-->
                  <!--<PictureView class="float-start" :defaultValue='accessRequest.requestedBy.firstName[0].toUpperCase() + accessRequest.requestedBy.lastName[0].toUpperCase()' :userId='accessRequest.requestedBy.id'></PictureView>-->
                  <PictureView class="float-start" :defaultValue='accessRequest.requestedBy.firstName[0].toUpperCase() + accessRequest.requestedBy.lastName[0].toUpperCase()' :imgURL='"/users/" + accessRequest.requestedBy.id + "/profile-picture"'></PictureView>

                  <h5 class="float-start" style="margin-left:15px;">{{upperCaseFirst(accessRequest.requestedBy.firstName)}} {{upperCaseFirst(accessRequest.requestedBy.lastName)}}</h5>
              </div>
              <div class="col-2">
                <div class="btn-group float-end">
                  <div class="btn btn-sm bg-danger text-white"  @click="requestToRemove = accessRequest;showRequestRemoveAlert = true;">Decline</div>
                  <div class="btn btn-sm bg-primary text-white" @click="requestToApprove = accessRequest;showApproveAlert = true;">Approve</div>
                </div>
              </div>
            </div>
            <hr v-bind:key="accessRequest.id + 'line'" style="margin-top:4px;"/>
          </template>
          <div class="row" style="margin-bottom:10px;">
            <div class="col text-start">
                <h4 class="float-start customFont">Existing Members</h4>
            </div>
          </div>
          <template  v-for="member in members">
            <div class="row" v-bind:key="member.id">
              <div class="col-11 text-start">
                  <!--<h5 class="userOrb float-start">
                    {{member.firstName[0].toUpperCase()}}{{member.lastName[0].toUpperCase()}}
                  </h5>-->
                    <!--<PictureView class="float-start" :defaultValue='member.firstName[0].toUpperCase() + member.lastName[0].toUpperCase()' :userId='member.userId'></PictureView>-->
                    <PictureView class="float-start" :defaultValue='member.firstName[0].toUpperCase() + member.lastName[0].toUpperCase()' :imgURL='"/users/" + member.userId + "/profile-picture"'></PictureView>
                  <h5 class="float-start" style="margin-left:15px;">{{upperCaseFirst(member.firstName)}} {{upperCaseFirst(member.lastName)}}</h5>
                </div>
              <div class="col-1">
                <div class="btn btn-sm bg-danger text-white float-end" v-if="member.userId != appRoot().currentUser.id" @click="memberToRemove = member;showRemoveAlert = true;"><span class="fa fa-times"></span></div>
              </div>
            </div>
            <hr v-bind:key="member.id + 'line'" style="margin-top:4px;"/>
          </template>
        </div>
        <AlertModal
           v-if="showRemoveAlert"
           title="Are you sure?"
           :description="removalDescription()"
           cancelText="Cancel"
           confirmText="Yes, Delete"
           @dismiss="showRemoveAlert = null"
           @confirm="removeMember()">
         </AlertModal>
         <AlertModal
            v-if="showRequestRemoveAlert"
            title="Are you sure?"
            :description="requestRemovalDescription()"
            cancelText="Cancel"
            confirmText="Yes, Decline"
            @dismiss="showRequestRemoveAlert = null"
            @confirm="declineRequest()">
          </AlertModal>
         <ProjectGroupPicker
            v-if="showApproveAlert"
            :description="approveDescription()"
            cancelText="Cancel"
            confirmText="Yes, Allow"
            @dismiss="showApproveAlert = null"
            @confirm="approveRequest">
          </ProjectGroupPicker>
         <AlertModal
            v-if="error != null"
            title="Error"
            :description="error"
            cancelText="Ok"
            @dismiss="error = null">
          </AlertModal>
      </div>
</template>

<script>
import AlertModal from '../../../../Components/AlertModal.vue'
import ProjectGroupPicker from '../../../../Components/ProjectGroupPicker.vue'
import PictureView from '../../../../Components/PictureView.vue'
import request from 'request';
export default {
  name: 'ProjectMembersView',
  components : {
    AlertModal,
    ProjectGroupPicker,
    PictureView
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    projectUrl : function(){
      return "https://macchiato.kappuccino.uk/projects/" + this.$route.params.projectId;
    },
    goBack : function(){
        this.$router.back();
    },
   fallbackCopyTextToClipboard : function(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
},
 copyTextToClipboard: function(text) {
  if (!navigator.clipboard) {
    this.fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
},
requestRemovalDescription : function(){
  return "Are you sure you want to decline " + this.upperCaseFirst(this.requestToRemove.requestedBy.firstName) + " " + this.upperCaseFirst(this.requestToRemove.requestedBy.lastName) + "'s request to join this project? We won't tell them.";
},
    removalDescription : function(){
      return "Are you sure you want to remove " + this.upperCaseFirst(this.memberToRemove.firstName) + " " + this.upperCaseFirst(this.memberToRemove.lastName) + " from this project?";
    },
    approveDescription : function(){
      return "Are you sure you want to allow " + this.upperCaseFirst(this.requestToApprove.requestedBy.firstName) + " " + this.upperCaseFirst(this.requestToApprove.requestedBy.lastName) + " to join this project? If so, please choose a group for them.";
    },
    upperCaseFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    hasPermissions : function(prefix){
      let permissionIdentifier = prefix + "_" + this.$route.params.projectId;
      let permissionRecordIndex = window.appRoot.currentPermissions.findIndex(function(record) {
        return record.permissionId == permissionIdentifier;
      });
      if (permissionRecordIndex > -1) {
        return window.appRoot.currentPermissions[permissionRecordIndex].access;
      }
      return false;
    },
    declineRequest : function(){
      var deleteWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/accessRequests/" + scope.requestToRemove.id,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "DELETE"
        }, function(error, response){
          if (response.statusCode != 200){
            scope.error = "We couldn't remove this request, please try again.";
            return
          }
          scope.fetchAccessRequests();
        });
      }
      deleteWith(this);
    },
    approveRequest : function(group) {
      var approveWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/accessRequests/" + scope.requestToApprove.id + "/approve",
          json: {
            "groupId" : group.id
          },
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "POST"
        }, function(error, response){
          if (response.statusCode != 200){
            scope.error = "We couldn't approve this request, please try again.";
            return
          }
          scope.fetchProjectMembers();
          scope.fetchAccessRequests();
        });
      }
      approveWith(this);
    },
    removeMember : function(){
      var deleteWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/members/" + scope.memberToRemove.userId,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "DELETE"
        }, function(error, response){
          if (response.statusCode != 200){
            scope.error = "We couldn't remove this project member, please try again.";
            return
          }
          scope.fetchProjectMembers();
        });
      }
      deleteWith(this);
    },
    fetchProjectMembers : function(){
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/members",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.members = jsonObject.members;
            return
          }
        });
      }
      fetchWith(this);
    },
    fetchAccessRequests : function(){
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/accessRequests",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.accessRequests = jsonObject.accessRequests;
            return
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      members : [],
      accessRequests : [],
      memberToRemove : null,
      requestToApprove : null,
      requestToRemove : null,
      showApproveAlert : false,
      showRemoveAlert : false,
      showRequestRemoveAlert : false,
      error : null
    }
  },
  created : function(){
    if (this.hasPermissions('macchiato_project_edit_permissions')){
      this.fetchProjectMembers();
      this.fetchAccessRequests();
    }else {
      window.location.href = "/projects/" + this.$route.params.projectId;
    }
  }
}
</script>
<style scoped>
/* .userOrb {
  font-size: 10px;
  background-color:rgb(18,153,226);
  border-radius:15px;
  width:30px;
  height:30px;
  color: white;
  text-align: center;
  vertical-align: middle;
  line-height:31px;
} */

.ontop-left {
  position:absolute;
  left:10;
}
</style>
