<template>
      <div class="card defaultMargin">
        <div class="card-header customFont d-flex justify-content-between align-items-center">
          <h5>{{appRoot().currentProject.name}} - Tasks</h5>
          <a href="newtask"><div class="btn btn-sm btn-primary">New Task</div></a>
        </div>
        <div class="card-body">
          <div class="input-group mb-3" v-if="!showFilters">
            <input type="text" placeholder="Search by title" class="form-control" v-model="filters.title"/>
              <div class="btn btn-sm bg-warning" style="padding-top:10px;width:45px;" v-on:click="showFilters = true;"><i class="fas fa-filter"></i></div>
          </div>
          <div>
            <div class="float-end" v-if="showFilters" style="padding-bottom:10px;">
              <div class="btn btn-sm bg-danger" style="padding-top:10px;width:45px;" v-on:click="showFilters = false;"><i class="fas fa-times"></i></div>
            </div>
          </div>
          <TaskFilterView v-if="showFilters" :filters="filters" @hide="showFilters = false"></TaskFilterView>
          <hr/>
          <template  v-for="task in tasks">
            <div class="row" v-bind:key="task.id" style="cursor:pointer;" v-on:click="navigateTo(task.id)">
              <div class="col-sm-1 text-end">
                <div class="col-lg text-end" v-if="task.assignedTo != null">
                  <!--<h5 class="userOrb">
                    {{task.assignedTo.firstName[0].toUpperCase()}}{{task.assignedTo.lastName[0].toUpperCase()}}
                  </h5>-->
                  <!--<PictureView class="float-start" :defaultValue='task.assignedTo.firstName[0].toUpperCase() + task.assignedTo.lastName[0].toUpperCase()' :userId='task.assignedTo.id'></PictureView>-->
                  <PictureView class="float-start" :defaultValue='task.assignedTo.firstName[0].toUpperCase() + task.assignedTo.lastName[0].toUpperCase()' :imgURL='"/users/" + task.assignedTo.id + "/profile-picture"'></PictureView>
                </div>
                <div class="col-lg">
                  <span :class="iconFor(task)"></span>
                </div>
              </div>
              <div class="col text-start">
                <span class="badge float-end" :style="queueStyle(task.queue)" v-if="task.queue != null">{{task.queue.name}}</span>
                <p>{{task.identifier}}</p>
                <h5>{{task.title}}</h5>
                <p>{{task.detailedDescription}}</p>
                <TagsView :tags="task.tags" :parentId="task.id"></TagsView>
                <p class="text-muted">{{dateStringForEpoch(task.createdAt)}}</p>
              </div>
            </div>
            <hr v-bind:key="task.id + 'line'"/>
          </template>
          <div class="row" v-if="canLoadMore">
            <div class="col text-center" style="cursor:pointer;" @click="loadMore">
              <h5 style="font-weight:bold;color:rgb(18,153,226);">Load More</h5>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import TagsView from '../../../../Components/TagsView.vue'
import TaskFilterView from '../../../../Components/TaskFilterView.vue'
import PictureView from '../../../../Components/PictureView.vue'
import request from 'request';
export default {
  name: 'TaskList',
  components : {
    TagsView,
    TaskFilterView,
    PictureView
  },
  watch : {
    filters : {
      handler : function() {
        this.skip  = 0;
        this.tasks = [];
        this.fetchProjectTasks();
      },
      deep: true
    }
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    queueStyle : function(queue){
      return {
        "background-color" : queue.hexColor
      }
    },
    dateStringForEpoch : function(epoch) {
      let date = new Date(epoch * 1000);
      let dateString = date.toLocaleDateString();
      let timeString = date.toLocaleTimeString();
      return dateString + " at " + timeString;
    },
    navigateTo : function(path){
      console.log(path);
      // if (this.$route.name == "projectMenu") {
      //   this.$router.push(this.$route.params.projectId + '/' + path);
      // }else {
        this.$router.push("tasks/" + path)
      //}
    },
    goBack : function(){
        this.$router.back();
    },
    loadMore : function(){
      this.skip += this.limit;
      this.fetchProjectTasks();
    },
    iconFor : function(task){
      var classDict = {
        "fas" : true,
        "fa-2x" : true,
        "float-end" : true
      }
      switch (task.priority) {
        case 5:
        classDict["fa-angle-double-up"] = true;
        classDict["text-danger"] = true;
        break;
        case 4:
        classDict["fa-angle-up"] = true;
        classDict["text-warning"] = true;
        break;
        case 3:
        classDict["fa-angle-right"] = true;
        classDict["text-default"] = true;
        break;
        case 2:
        classDict["fa-angle-down"] = true;
        classDict["text-primary"] = true;
        break;
        case 1:
        classDict["fa-angle-double-down"] = true;
        classDict["text-success"] = true;
        break;
        default:
        break;
      }
      return classDict;
    },
    fetchProjectTasks : function(){
      var fetchWith = function(scope) {
        scope.canLoadMore = false;
        var params = scope.filters;
        //inject non-filter params
        params["limit"] = scope.limit;
        params["offset"] = scope.skip;

        var cleanParams = {}; //Remove NULL values that cause issues... If the filter value is NULL then just don't include it at all in the request.
        for (var key in params) {
          if (params[key] != null){
            cleanParams[key] = params[key];
          }
        }
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tasks",
          qs : cleanParams,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.tasks = scope.tasks.concat(jsonObject.tasks);
            if (jsonObject.tasks.length == scope.limit){
              scope.canLoadMore = true;
            }
            return
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      tasks : [],
      filters : {
        sortBy : "createdAt",
        sortOrder : "DESC",
        title : "",
        detailedDescription: "",
        closed : false
      },
      showFilters : false,
      canLoadMore : false,
      skip : 0,
      limit : 8
    }
  },
  created : function(){
    this.fetchProjectTasks();
  }
}
</script>
<style scoped>
/* .userOrb {
  font-size: 16px;
  background-color:rgb(18,153,226);
  border-radius:20px;
  width:40px;
  height:40px;
  color: white;
  text-align: center;
  vertical-align: middle;
  line-height:40px;
} */

.ontop-left {
  position:absolute;
  left:10;
}
</style>
