<template>
  <div class="container">
    <div class="card defaultMargin">
      <div class="card-header customFont d-flex justify-content-between align-items-center">
        <h5>{{appRoot().currentProject.name}} - Notification Preferences</h5>
      </div>
      <div class="card-body text-start">
        <p>When and how would you like to receive notifications for this project?</p>
        <div class="row" v-for="pref in notifications" v-bind:key="pref.id">
          <p class="text-muted">{{pref.notificationDescription}}</p>
          <div class="input-group">
            <label class="input-group-text">Push Notifications</label>
            <input class="form-control" type="text" disabled/>
            <div class="btn btn-warning" v-if="pref.push == true" @click="toggle('push',pref)">Disable</div>
            <div class="btn btn-primary" v-if="pref.push != true" @click="toggle('push',pref)">Enable</div>
          </div>
          <div class="input-group" style="margin-top:15px;">
            <label class="input-group-text">Email Notifications</label>
            <input class="form-control" type="text" disabled/>
            <div class="btn btn-warning" v-if="pref.email == true"  @click="toggle('email',pref)">Disable</div>
            <div class="btn btn-primary" v-if="pref.email != true"  @click="toggle('email',pref)">Enable</div>
          </div>
          <hr style="margin-top:15px;"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from 'request';
export default {
  name: 'NotificationPreferencesView',
  components : {
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    goBack : function(){
      this.$router.back();
    },
    toggle : function(key, pref){
      var updateDictionary = {
        "push" : pref.push,
        "email" : pref.email
      }
      updateDictionary[key] = !updateDictionary[key];
      //PUT
      var self = this;
        request({
          url: window.apiUrl + "/projects/" + self.$route.params.projectId + "/preferences/notifications/" + pref.notificationIdentifier,
          headers : {
            "x-token-type" : "JWT"
          },
          json: updateDictionary,
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "PUT"
        }, function(error, response){
          if (error != null) {
            return
          }
          if (response.statusCode != 200){
            return
          }
          self.fetchProjectNotificationPreferences();
        });
    },
    fetchProjectNotificationPreferences : function(){
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/preferences/notifications",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.notifications = jsonObject.notifications.sort(function(a,b){
              return a.notificationDescription > b.notificationDescription;
            });
            return
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      notifications : []
    }
  },
  created : function(){
    this.fetchProjectNotificationPreferences();
  }
}
</script>
