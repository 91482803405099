<template>
  <div>
    <div class="row">
      <div class="col-md">
        <div class="card defaultMargin" v-if="group != null">
          <div class="card-header customFont d-flex justify-content-between align-items-center">
            <h5>{{group.name}}</h5>
            <div class="dropdown" v-if="!loading">
    <button class="btn btn-sm btn-dark dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
      Options
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li class="dropdown-item text-danger" @click="deleteGroup();">Delete Group</li>
    </ul>
  </div>
          </div>
          <div class="card-body">

            <nav>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active" id="nav-members-tab" data-bs-toggle="tab" data-bs-target="#nav-members" type="button" role="tab" aria-controls="nav-members" aria-selected="true">Members</button>
                <button class="nav-link" id="nav-permissions-tab" data-bs-toggle="tab" data-bs-target="#nav-permissions" type="button" role="tab" aria-controls="nav-permissions" aria-selected="false">Permissions</button>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div class="tab-pane fade show active text-start" id="nav-members" role="tabpanel" aria-labelledby="nav-members-tab">
                <br/>
                <ProjectMemberPicker label="Add Member" chooseLabel="Select" changeLabel="Change" :selectedMember="newMember" @didSelect="addNewMember" />
                <button v-if="newMember != null" class="btn bg-primary text-white float-end" @click="addProjectMember(newMember)">Add {{upperCaseFirst(newMember.firstName)}} to this group</button>
              <br/><br/>
                <template  v-for="member in group.members">
                  <div class="row" v-bind:key="member.id">
                    <div class="col-11 text-start">
                      <!--<h5 class="userOrb float-start">
                        {{member.firstName[0].toUpperCase()}}{{member.lastName[0].toUpperCase()}}
                      </h5>-->
                      <!--<PictureView class="float-start" :defaultValue='member.firstName[0].toUpperCase() + member.lastName[0].toUpperCase()' :userId='member.userId'></PictureView>-->
                      <PictureView class="float-start" :defaultValue='member.firstName[0].toUpperCase() + member.lastName[0].toUpperCase()' :imgURL='"/users/" + member.userId + "/profile-picture"'></PictureView>
                      <h5 class="float-start" style="margin-left:15px;">{{upperCaseFirst(member.firstName)}} {{upperCaseFirst(member.lastName)}}</h5>
                    </div>
                    <div class="col-1">
                      <div class="btn btn-sm bg-danger text-white float-end" v-if="member.userId != appRoot().currentUser.id" @click="removeProjectMember(member)"><span class="fa fa-times"></span></div>
                    </div>
                  </div>
                  <hr v-bind:key="member.id + 'line'" style="margin-top:4px;"/>
                </template>
              </div>
              <div class="tab-pane fade text-start" id="nav-permissions" role="tabpanel" aria-labelledby="nav-permissions-tab">
                <br/>
                <template v-if="group.locked == true">
                  <p style="text-muted">Sorry, you can't change the permissions for this group. Project Owners is locked to prevent loss of access.</p>
                </template>
                <template  v-for="permission in group.permissions">
                  <div class="row" v-bind:key="permission.permissionId">
                    <div class="col-10 text-start">
                      <h5 class="float-start">{{permission.permissionDescription}}</h5>
                    </div>
                    <div class="col-2">
                      <template v-if="group.locked != true">
                      <div class="btn-group" v-if="permission.access != true">
                        <button class="btn btn-sm bg-primary text-white">No</button>
                        <button class="btn btn-sm bg-light" @click="changePermission(permission, true)">Yes</button>
                      </div>
                      <div class="btn-group" v-if="permission.access == true">
                        <button class="btn btn-sm bg-light" @click="changePermission(permission, false)">No</button>
                        <button class="btn btn-sm bg-primary text-white">Yes</button>
                      </div>
                    </template>
                    <div class="btn-group" v-if="group.locked == true && permission.access == true">
                      <button class="btn btn-sm bg-light">No</button>
                      <button class="btn btn-sm bg-secondary text-white">Yes</button>
                    </div>
                    <div class="btn-group" v-if="group.locked == true && permission.access != true">
                      <button class="btn btn-sm bg-secondary text-white">No</button>
                      <button class="btn btn-sm bg-light">Yes</button>
                    </div>
                    </div>
                  </div>
                  <hr v-bind:key="permission.permissionId + 'line'" style="margin-top:4px;"/>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectMemberPicker from '../../../../Components/ProjectMemberPicker.vue';
import PictureView from '../../../../Components/PictureView.vue';
import request from 'request';
export default {
  name: 'ProjectGroupView',
  components : {
    ProjectMemberPicker,
    PictureView
  },
  methods : {
    upperCaseFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    appRoot : function(){
      return window.appRoot;
    },
    addNewMember : function(member){
      this.newMember = member;
    },
    changePermission : function (permission, access) {
      if (permission.id == null) {
        this.adoptPermission(permission.permissionId, access);
        return;
      }
      this.editExistingPermission(permission.id, access);
    },
    editExistingPermission : function(id, access){
      var self = this;
      self.loading = true;
      request({
        url: window.apiUrl + "/projects/" + self.$route.params.projectId + "/permissions/" + id,
        headers : {
          "x-token-type" : "JWT"
        },
        auth : {
          "bearer" : window.localStorage.getItem('macchiato-token')
        },
        json : {
          "access" : access
        },
        method : "PUT"
      }, function(){
        self.loading = false;
        self.fetchProjectGroup();
      });
    },
    adoptPermission : function(permissionId, access) {
      var self = this;
      self.loading = true;
      request({
        url: window.apiUrl + "/projects/" + self.$route.params.projectId + "/permissions",
        headers : {
          "x-token-type" : "JWT"
        },
        auth : {
          "bearer" : window.localStorage.getItem('macchiato-token')
        },
        json : {
          "access" : access,
          "permissionId" : permissionId,
          "groupId" : self.$route.params.groupId
        },
        method : "POST"
      }, function(){
        self.loading = false;
        self.fetchProjectGroup();
      });
    },
    removeProjectMember : function(member) {
      var self = this;
      self.loading = true;
      request({
        url: window.apiUrl + "/projects/" + self.$route.params.projectId + "/groups/" + self.$route.params.groupId + "/members/" + member.userId,
        headers : {
          "x-token-type" : "JWT"
        },
        auth : {
          "bearer" : window.localStorage.getItem('macchiato-token')
        },
        method : "DELETE"
      }, function(){
        self.loading = false;
        self.fetchProjectGroup();
      });
    },
    deleteGroup : function() {
      var self = this;
      self.loading = true;
      request({
        url: window.apiUrl + "/projects/" + self.$route.params.projectId + "/groups/" + self.$route.params.groupId,
        headers : {
          "x-token-type" : "JWT"
        },
        auth : {
          "bearer" : window.localStorage.getItem('macchiato-token')
        },
        method : "DELETE"
      }, function(){
        self.loading = false;
        window.location = "/projects/" + self.$route.params.projectId + "/groups";
      });
    },
    addProjectMember : function(member) {
      var self = this;
      self.loading = true;
      request({
        url: window.apiUrl + "/projects/" + self.$route.params.projectId + "/groups/" + self.$route.params.groupId + "/members/" + member.id,
        headers : {
          "x-token-type" : "JWT"
        },
        auth : {
          "bearer" : window.localStorage.getItem('macchiato-token')
        },
        method : "POST"
      }, function(){
        self.loading = false;
        self.newMember = null;
        self.fetchProjectGroup();
      });
    },
    fetchProjectGroup : function(){
      var fetchWith = function(scope) {
        scope.loading = true;
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/groups/" + scope.$route.params.groupId,
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          scope.loading = false;
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.group = jsonObject;
            scope.group.permissions.sort(function(p1, p2){
              return p1.permissionDescription > p2.permissionDescription;
            });
            return
          }
        });
      }
      fetchWith(this);
    }
  },
  created : function() {
    this.fetchProjectGroup();
  },
  data : function(){
    return {
      group : null,
      loading : false,
      newMember : null
    }
  }
}
</script>
<style scoped>
/* .userOrb {
  font-size: 10px;
  background-color:rgb(18,153,226);
  border-radius:15px;
  width:30px;
  height:30px;
  color: white;
  text-align: center;
  vertical-align: middle;
  line-height:31px;
} */

.ontop-left {
  position:absolute;
  left:10;
}
</style>
