<template>
  <div :class="containerClass()" style="display: inline-block">
    <p style="font-weight:bold;" v-if="!isYou()">{{upperCaseFirst(timelineItem.createdBy.firstName)}} {{upperCaseFirst(timelineItem.createdBy.lastName)}}</p>
    <p style="font-weight:bold;" v-if="isYou()">You</p>
    <p :class="messageClass()" style="display:inline-block;" v-if="timelineType === 'discussion'">{{timelineItem.taskDiscussion.message}}</p>
    <!--<p class="customFont" :class="messageClass()" style="display:inline-block;" v-if="timelineType === 'activity'">{{timelineItem.activity.resource.name}} {{timelineItem.activity.event.name}}</p>-->
    <p class="customFont" style="display:inline-block;" v-if="timelineType === 'activity'">{{timelineItem.activity.resource.name}} {{timelineItem.activity.event.name}}</p>
    <br/>
    <p class="customFont" style="display:inline-block;" v-if="showUpdatedProperty == true">{{timelineItem.activity.updatedProperty.name}} changed from {{timelineItem.activity.updatedProperty.originalValue}} to {{timelineItem.activity.updatedProperty.newValue}}.</p>
    <div v-if="showImage">
      <img height='200px' :src='imageLocation' @click="refreshDownloadLink()" style="cursor: pointer"/>
    </div>
    <div v-if="showIcon">
      <span @click="refreshDownloadLink()" style="cursor: pointer">
        <i :class="iconType" style="font-size: 100px"></i>
      </span>
      <p class="text-muted" style="font-size:12px;">{{timelineItem.attachment.displayName}}</p>
    </div>
    <p class="text-muted" style="font-size:12px;">{{prettyDate()}}</p>
  </div>
</template>

<script>
import request from 'request';
export default {
  name: 'TimelineBlock',
  props : ['timelineItem'],
  methods : {
    prettyDate() {
      let newDate = new Date(this.timelineItem.createdAt * 1000);
      return newDate.toLocaleString();
    },
    upperCaseFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    isYou : function(){
      return window.appRoot.currentUser.id == this.timelineItem.createdBy.id;
    },
    checkType : function(){
      if (Object.prototype.hasOwnProperty.call(this.timelineItem,"taskDiscussion")) {
        console.log("this is a discussion")
        this.timelineType = "discussion";
        return;
      }

      // if (this.timelineItem.hasOwnProperty("taskDiscussion")) {
      //   return "discussion";
      // }
      
      if (Object.prototype.hasOwnProperty.call(this.timelineItem,"attachment")){
        console.log("this is an attachment")
        this.timelineType = "attachment"
        return;
      }

      if (Object.prototype.hasOwnProperty.call(this.timelineItem,"activity")){
        console.log("this is an activity")
        this.timelineType = "activity";
        if (Object.prototype.hasOwnProperty.call(this.timelineItem.activity,"updatedProperty")){
          this.showUpdatedProperty = true;
        }
        return;
      }
    },
    containerClass : function(){
      return {
        "text-start" : !this.isYou(),
        "text-end" : this.isYou(),
        "float-start" : !this.isYou(),
        "float-end" : this.isYou()
      }
    },
    messageClass : function(){
      return {
        "message" : !this.isYou(),
        "personalMessage" : this.isYou(),
        "text-start" : true
      }
    },
    fetchFile : function() {
      console.log("in fetchfile")
      var self = this
      self.s3Url = window.apiUrl + "/projects/" + self.$route.params.projectId + "/files/" + self.timelineItem.attachment.id + "/download/";
      self.imageLocation = "";

      // /projects/:projectId/files/:fileId/download
      
      request({
        url: self.s3Url,
        method: "GET",
        followAllRedirects: false,
        auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          }
      }, function(error, response) {
        //if (response.statusCode != 200 || response.headers["x-presigned-url"] == null) {
        console.log("fetchFile status code is " + response.statusCode)
        console.log(response.url)
        if (response.statusCode != 200 || response.url == null) {
          console.log("s3 head failed")
          return
        }

        self.imageLocation = response.url;
        this.imageLocation = response.url;
        console.log("fetch file function type is " + this.timelineType);

        if (self.timelineType == "attachment"){
          if (self.knownImageTypes.includes(self.timelineItem.attachment.fileExtension) && self.imageLocation != "" ){
            self.showImage = true
          } else {
            self.showImage = false
            
            if (self.knownFileTypes.includes(self.timelineItem.attachment.fileExtension)){
              //"xls","xlsx","doc","docx","pdf","zip","ppt","pptx","csv"
              switch (self.timelineItem.attachment.fileExtension){
                case "xls":
                  self.showIcon = true;
                  self.iconType = "fa fa-file-excel";
                  break;
                case "xlsx":
                  self.showIcon = true;
                  self.iconType = "fa fa-file-excel";
                  break;
                case "doc":
                  self.showIcon = true;
                  self.iconType = "fa fa-file-word";
                  break;
                case "docx":
                  self.showIcon = true;
                  self.iconType = "fa fa-file-word";
                  break;
                case "pdf":
                  self.showIcon = true;
                  self.iconType = "fa fa-file-pdf";
                  break;
                case "zip":
                  self.showIcon = true;
                  self.iconType = "fa fa-file-zipper";
                  break;
                case "ppt":
                  self.showIcon = true;
                  self.iconType = "fa fa-file-powerpoint";
                  break;
                case "pptx":
                  self.showIcon = true;
                  self.iconType = "fa fa-file-powerpoint";
                  break;
                case "csv":
                  self.showIcon = true;
                  self.iconType = "fa fa-file-csv";
                  break;
                default:
                  self.showIcon = true;
                  self.iconType = "fa fa-file";
              }
            }
          }
        } else {
          self.showImage = false
        }

        console.log("showImage is " + self.showImage);
        console.log("showIcon is " + self.showIcon);
        console.log("iconType = " + self.iconType);

        return;

      })
    },
     async refreshDownloadLink() {
      console.log('starting refresh')
      console.log('image url at start is: ' + this.imageLocation)

      await request({
        url: this.s3Url,
        method: "GET",
        followAllRedirects: false,
        auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          }
      }, function(error, response) {
        console.log("fetchFile status code is " + response.statusCode)
        //console.log(response.url)
        if (response.statusCode != 200 || response.url == null) {
          console.log("s3 head failed")
          return
        }

        this.imageLocation = response.url;
        
        
        // annoyingly having this inside the request function makes the refresh work but not the downloading!
        
        console.log('image url after is: ' + this.imageLocation)
        //return;

      })

      window.open(this.imageLocation, "_blank")
      //window.open(this.imageLocation, "Download File")


      // const testVar = await this.fetchFile();
      // console.log(testVar)

      //await this.fetchFile()

     
      //return this.imageLocation;

    },
    checkShowImage: function() {
      if (Object.prototype.hasOwnProperty.call(this.timelineItem,"attachment")){
        console.log(this.timelineItem.attachment.fileExtension)
        console.log(this.knownImageTypes)
        console.log(this.imageLocation)
        if (this.knownImageTypes.includes(this.timelineItem.attachment.fileExtension) && this.imageLocation != "" ){
          this.showImage = true
        } else {
          this.showImage = false
        }
      } else {
        this.showImage = false
      }

      console.log(this.showImage);

    }
  },
  data: function() {
    return {
      s3Url: "",
      showImage: false,
      showIcon: false,
      iconType: "",
      imageLocation: "",
      knownImageTypes: ["jpg", "jpeg","png", "bmp", "gif", "heic"],
      knownFileTypes: ["xls","xlsx","doc","docx","pdf","zip","ppt","pptx","csv","txt"],
      timelineType: "",
      showUpdatedProperty: false
    }
  },
  created: function(){
    console.log("created function")
    this.checkType();
    console.log("created function type is " + this.timelineType);
    if (this.timelineType == "attachment"){
      this.fetchFile();
      //this.checkShowImage();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

p.message {
    background-color: #F2F2F2;
    padding:5px;
    border-radius:4px;
    color: #000;
}
p.personalMessage {
    background-color: #147efb;
    padding:5px;
    border-radius:4px;
    color: #FFF;
}

</style>
