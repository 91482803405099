<template>
  <div :class="containerClass()">
    <p style="font-weight:bold;" v-if="!isYou()">{{upperCaseFirst(discussion.firstName)}} {{upperCaseFirst(discussion.lastName)}}</p>
    <p style="font-weight:bold;" v-if="isYou()">You</p>
    <p :class="messageClass()" style="display:inline-block;">{{discussion.message}}</p>
    <p class="text-muted" style="font-size:12px;">{{prettyDate()}}</p>
  </div>
</template>

<script>
export default {
  name: 'DiscussionBlock',
  props : ['discussion'],
  methods : {
    prettyDate() {
      let newDate = new Date(this.discussion.createdAt * 1000);
      return newDate.toLocaleString();
    },
    upperCaseFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    isYou : function(){
      return window.appRoot.currentUser.id == this.discussion.createdBy;
    },
    containerClass : function(){
      return {
        "text-start" : !this.isYou(),
        "text-end" : this.isYou(),
        "float-start" : !this.isYou(),
        "float-end" : this.isYou()
      }
    },
    messageClass : function(){
      return {
        "message" : !this.isYou(),
        "personalMessage" : this.isYou(),
        "text-start" : true
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

p.message {
    background-color: #F2F2F2;
    padding:5px;
    border-radius:4px;
    color: #000;
}
p.personalMessage {
    background-color: #147efb;
    padding:5px;
    border-radius:4px;
    color: #FFF;
}

</style>
