import { render, staticRenderFns } from "./KanbanList.vue?vue&type=template&id=c1429bb2&scoped=true&"
import script from "./KanbanList.vue?vue&type=script&lang=js&"
export * from "./KanbanList.vue?vue&type=script&lang=js&"
import style0 from "./KanbanList.vue?vue&type=style&index=0&id=c1429bb2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1429bb2",
  null
  
)

export default component.exports