<template>
  <form v-on:submit.prevent="preventFormSubmit">
    <div class="form-group">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Search by title" v-model="filters.title"/>
          <div class="btn btn-sm bg-dark text-white" style="padding-top:10px;width:45px;" @click="$emit('hide')"><span class="fas fa-times"></span></div>
      </div>
    </div>
    <div class="form-group">
      <input type="text" class="form-control" placeholder="Search description" v-model="filters.detailedDescription"/>
    </div>
    <div class="form-group text-start">
      <div class="btn-group" style="margin-top:10px;" id="sortBy">
        <div :class="classForFilter('closed',null)" v-on:click="filters.closed = null;">Any</div>
        <div :class="classForFilter('closed',false)" v-on:click="filters.closed = false;">Open</div>
        <div :class="classForFilter('closed',true)" v-on:click="filters.closed = true;">Closed</div>
      </div>
    </div>
    <div class="form-group text-start">
      <h5>Sort By</h5>
      <div class="btn-group" id="sortBy">
        <div v-for="(field, key) in sortableFields" :class="classForSortableField(key)" v-on:click="filters.sortBy = key;" v-bind:key="key">{{field.name}}</div>
      </div>
      <br/>
      <div class="btn-group" style="margin-top:10px;" id="sortBy">
        <div :class="classForSortOrder('ASC')" v-on:click="filters.sortOrder = 'ASC';">Ascending</div>
        <div :class="classForSortOrder('DESC')" v-on:click="filters.sortOrder = 'DESC';">Descending</div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'RequestFilterView',
  props : ['filters'],
  methods : {
    preventFormSubmit : function(){
      return;
    },
    classForFilter : function(filter,key){
      if (key == this.filters[filter]){
        return {
          "btn" : true,
          "btn-primary" : true
        }
      }
      return {
        "btn" : true,
        "btn-light" : true
      }
    },
    classForSortableField : function(key){
      if (key == this.filters.sortBy){
        return {
          "btn" : true,
          "btn-primary" : true
        }
      }
      return {
        "btn" : true,
        "btn-light" : true
      }
    },
    classForSortOrder : function(key){
      if (key == this.filters.sortOrder){
        return {
          "btn" : true,
          "btn-primary" : true
        }
      }
      return {
        "btn" : true,
        "btn-light" : true
      }
    }
  },
  data : function(){
    return {
      sortableFields : {
        createdAt : {
          name : "Created Date"
        },
        title : {
          name : "Title"
        },
        priority : {
          name : "Priority"
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
