<template>
  <div>
      <div class="card defaultMargin">
        <div class="card-header customFont d-flex justify-content-between align-items-center">
          <h5>{{appRoot().currentProject.name}} - Queues</h5>
          <a href="newqueue">
            <div class="btn btn-sm btn-primary">New Queue</div>
          </a>
        </div>
        <div class="card-body text-start">
          <p class="text-muted">Drag and drop queues to re-order them.</p>
            <!--<template v-for="queue in queues">
            <div class="row dragItem" style="cursor:pointer" @click="queueToView = queue" v-bind:key="queue.id">
            <div class="col text-start">
            <h5 class="queueClass" :style="queueStyle(queue)">{{queue.name}}</h5>
          </div>
        </div>
        <hr v-bind:key="queue.id + 'line'" />
      </template>-->
          <draggable v-model="queues" group="queues" @change="dragDidChange" draggable=".dragItem" tag="ul" class="list-group">
      <li v-for="queue in queues" class="list-group-item dragItem" style="cursor:pointer" @click="navigateTo(queue.id);" v-bind:key="queue.id">
            <!--<i class="fas fa-grip-lines float-start" style="margin-top:5px;"></i>-->
            <h5 class="float-start" style="margin-top:10;margin-bottom:10;margin-left:10px;"><span class="badge" :style="queueStyle(queue)">{{queue.name}}</span></h5>
            <!--<i class="fa fa-chevron-right float-end" style="margin-top:5px;"></i>-->
      </li>
      </draggable>
  </div>
</div>
</div>
</template>

<script>
import draggable from 'vuedraggable'
import request from 'request';
export default {
  name: 'QueueList',
  components : {
    draggable
  },
  watch : {
    filters : {
      handler : function() {
        this.queues = [];
        this.fetchProjectQueues();
      },
      deep: true
    }
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    navigateTo : function(path){
      console.log(path);
      // if (this.$route.name == "projectMenu") {
      //   this.$router.push(this.$route.params.projectId + '/' + path);
      // }else {
      this.$router.push("queues/" + path);
      //}
    },
    goBack : function(){
      this.$router.back();
    },
    queueStyle : function(q){
      var styleDict = {
        "cursor" : "pointer",
        "background-color" : q.hexColor,
        "display" : "inline-block"
      }
      return styleDict;
    },
    fetchProjectQueues : function(){
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/queues",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          if (response.statusCode != 200){
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            scope.queues = jsonObject.queues;
            return
          }
        });
      }
      fetchWith(this);
    },
    updateSortIndex : function(){
      var fetchWith = function(scope) {

        var updateArray = [];
        for (var i = 0; i < scope.queues.length; i++){
          updateArray.push({
            id : scope.queues[i].id,
            sortIndex : i
          });
        }

        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/queues/sort",
          json : {
            queues: updateArray
          },
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "PUT"
        }, function(error, response){
          if (response.statusCode != 200){
            return
          }
          scope.$emit('dismiss');
        });
      }
      fetchWith(this);
    },
    dragDidChange: function(evt) {
      console.log(evt);
      this.updateSortIndex();
    }
  },
  data : function(){
    return {
      queues : []
    }
  },
  created : function(){
    this.fetchProjectQueues();
  }
}
</script>
<style>
.queueClass {
  height:28px;
  border-radius: 8px;
  line-height: 8px;
  padding: 10px;
  color: #fff;
}
.dragItem:HOVER {
  background-color:#F1F1F1;
}
.ontop-left {
  position:absolute;
  left:10;
}
</style>
