<template>
  <div class="card defaultMargin">
    <div class="card-header customFont d-flex justify-content-between align-items-center">
      <h5>{{appRoot().currentProject.name}} - New Tag</h5>
    </div>
    <div class="card-body text-start">
      <div class="mb-3">
        <label class="form-label">Tag Label</label>
        <input type="text" class="form-control" v-model="title">
      </div>
      <div class="mb-3">
        <label class="form-label">Description</label>
        <textarea type="text" class="form-control" v-model="description"></textarea>
      </div>
      <div class="mb-3">
        <label class="form-label">Select Tag Colour</label>
        <input type="color" class="form-control form-control-color" v-model="hexColor">
      </div>
      <div class="mb-3">
        <div class="btn btn-warning float-start" @click="cancel()">Cancel</div>
        <div class="btn btn-primary float-end" @click="createProjectTag()">Save</div>
      </div>
    </div>
  </div>
</template>

<script>
import request from 'request';
export default {
  name: 'NewTask',
  components : {
  },
  methods : {
    appRoot : function(){
      return window.appRoot;
    },
    cancel : function(){
        window.location.href = "/projects/" + this.$route.params.projectId + "/tags";
    },
    createProjectTag : function(){
      if (this.title == null || this.description == null) {
        return;
      }
      var fetchWith = function(scope) {

        request({
          url: window.apiUrl + "/projects/" + scope.$route.params.projectId + "/tags",
          json : {
            title : scope.title,
            description: scope.description,
            hexColor: scope.hexColor
          },
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "POST"
        }, function(error, response, json){
          if (response.statusCode != 201){
            return
          }
          if (error != null){
            console.log("whatttt");
            return
          }
          if (json != null){
            //Navigate to that task using the new identifier created...
            window.location.href = "tags";
            return
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function(){
    return {
      title : "",
      description : "",
      hexColor: "#000000"
    }
  },
  created : function(){
  }
}
</script>
