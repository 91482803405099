<template>
  <div class="homeWrapper">
    <template v-if="validToken && !loading">
      <NavigationBar></NavigationBar>
      <router-view></router-view>
    </template>
    <template v-if="loading == true">
      <div class="row">
        <div class="col-sm"></div>
        <div class="col-sm"><LogoView/></div>
        <div class="col-sm"></div>
      </div>
      <div class="row">
        <div class="col-md"></div>
        <div class="col-md">
          <div class="card defaultMargin">
            <div class="card-header">
              Please Wait
            </div>
            <div class="card-body">
              <h5 class="card-title">Validating Token</h5>
              <p class="card-text">Please wait, we're just validating your session.</p>
            </div>
          </div>
        </div>
        <div class="col-md"></div>
      </div>
    </template>
  </div>
</template>

<script>
import NavigationBar from '../Components/NavigationBar.vue';
import LogoView from '../Components/LogoView.vue';
import request from 'request';
export default {
  name: 'Home',
  components : {
    NavigationBar,
    LogoView
  },
  methods : {
    validateToken: function(){
      this.loading = true;
      var fetchWith = function(scope) {
        request({
          url: window.apiUrl + "/me",
          headers : {
            "x-token-type" : "JWT"
          },
          auth : {
            "bearer" : window.localStorage.getItem('macchiato-token')
          },
          method : "GET"
        }, function(error, response, data){
          scope.loading = false;
          if (response.statusCode != 200){
            window.localStorage.removeItem('macchiato-token');
            scope.$router.push('/login');
            return
          }
          var jsonObject = JSON.parse(data);
          if (error != null){
            return
          }
          if (jsonObject != null){
            if (jsonObject.id != null){
              window.appRoot.currentUser = jsonObject;
              scope.validToken = true;
              return
            }
          }
        });
      }
      fetchWith(this);
    }
  },
  data : function() {
    return {
      validToken : false,
      loading : false
    }
  },
  created : function() {
    //If local session token doesnt exist
    //redirect to login
    //this.$router.push('/login');
    if (window.localStorage.getItem('macchiato-token') == null) {
      this.$router.push('/login');
      return;
    }
    this.validateToken();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.hello {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
