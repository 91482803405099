<template>
  <div>
    <h1 class="customFont">Macchiato</h1>
    <center>
      <div style="max-width: 200px;" class="defaultMargin">
          <vue-apple-signin></vue-apple-signin>
      </div>
    </center>
    <p class="defaultMargin">- OR -</p>
    <button class="btn activeLink defaultMargin" v-on:click="signInWithEmail">Sign in with Email</button>
  </div>
</template>

<script>
export default {
  name: 'LoginWelcome',
  methods : {
    signInWithEmail : function(){
      this.$router.push('/login/email')
    }
  }
}
</script>
